import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import { ModuleTableItem } from "./Components/ModuleTableItem";
import { Requester } from "../../../Requester/Requester";
import { TModule } from "../../../Types/Module.type";
export function AdminModules() {
  const [modules, setModule] = useState<TModule[]>([]);
  const [modulesFilter, setModuleFilter] = useState<TModule[]>([]);
  const [searchName, setSearchName] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const fillModules = async () => {
      const response = await Requester.modules.getAll();
      if (response.data) {
        setModule(response.data);
      }
    };
    fillModules();
  }, [location]);

  useEffect(() => {
    setModuleFilter(
      modules
        .filter((item) =>
          searchName
            ? item.title
                .toLowerCase()
                .search(searchName.toLowerCase()) > -1
            : true
        )
        .sort((a, b) => {
          let fullA = a.title;
          let fullB = b.title;
          if (fullA < fullB) {
            return -1;
          } else if (fullA > fullB) {
            return 1;
          }
          return 0;
        })
    );
  }, [modules, searchName]);

  const handleSearchName = (value: string) => {
    setSearchName(value);
  };
  return (
    <nav>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h3>Gestion des Modules</h3>
        <Link to={`/admin/modules/new`} className="icon-rondelle">
          <img
            src="/bordures/icon-rondelle-add.svg"
            alt="Ajout d'un module"
          />
        </Link>
      </div>

      <div className="bambou-border-large main-box">
        <Table striped hover>
          <thead>
            <tr>
              <th className={"col-small"}><div>Photos</div></th>
              <th>
                <div><label htmlFor="search-name">Titre :</label>{" "}
                <input
                  id="search-name"
                  value={searchName}
                  onChange={(e) => handleSearchName(e.target.value)}
                ></input></div>
              </th>
              <th className={"col-small"}><div>Durée</div></th>
              <th className={"col-small"}><div>Prix</div></th>
              <th className={"col-small"}><div>Actions</div></th>
            </tr>
          </thead>
          <tbody>
            {modulesFilter.map((item, i) => (
              <ModuleTableItem key={i} module={item} />
            ))}
          </tbody>
        </Table>
      </div>
    </nav>
  );
}
