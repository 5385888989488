import { useState, useEffect, useContext } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { TModule } from "../../../Types/Module.type";
import { Requester } from "../../../Requester/Requester";

export function AdminModuleDelete() {
  const id = useLoaderData() as number;

  const {token} = useContext(TokenContext)
  const [module, setModule] = useState<TModule>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillModule = async () => {
      const response = await Requester.modules.getOne(id);
      if (response.data) {
        setModule(response.data);
      } else {
        setModule(undefined);
        navigate(`/admin/modules`)
      }
    };
    
    fillModule();
  }, [id,navigate]);
  
  const handleRequest = async () => {
      const response = await Requester.modules.delete(id,token) ;
      await Promise.all([response]) ;
      navigate(`/admin/modules`)
  }

  return (
    <>
    <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
      <h4>Suppression d'un Module</h4>
      </div>
      <div className="bambou-border-large main-box">
      <p>Etes-vous sur de vouloir supprimer {module?.title}</p>
        <Link
          className="rondelle-border"
          to={`/admin/modules/view/${id}`}
          onClick={(e) => {
            e.preventDefault();
            handleRequest();
          }}
        >
          <div>Oui</div>
        </Link>
        <Link className="rondelle-border" to={`/admin/modules/view/${id}`}>
          <div>Non</div>
        </Link>
      </div>
    </>
  );
}
