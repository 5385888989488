import { Link } from "react-router-dom";
import { TProgramme } from "../../../../Types/Programme.type";
import { minToHourString } from "../../../../Utilities/MinToHourString";

export function ProgrammeTableItem(props: { programme: TProgramme }) {
  const { programme } = props;

  return (
    <tr className={"compact"}>
      <td>
        <Link to={`/admin/programmes/view/${programme.id}`}>
          {programme.title}
        </Link>
      </td>
      <td>
        <Link to={`/admin/programmes/view/${programme.id}`}>
          {programme.formations.length}
        </Link>
      </td>
      <td>
        <Link to={`/admin/programmes/view/${programme.id}`}>
          {`${minToHourString(programme.duration)}`}
        </Link>
      </td>
      <td>
        <Link to={`/admin/programmes/view/${programme.id}`}>
          {programme.price}€
        </Link>
      </td>
      <td>
        <Link to={`/admin/programmes/view/${programme.id}`} className="barre">
          {programme.fullprice}€
        </Link>
      </td>
      <td>
        <div>
          <Link
            to={`/admin/programmes/update/${programme.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-update.svg"
              alt="Modifier un programme"
            />
          </Link>
          <Link
            to={`/admin/programmes/delete/${programme.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-delete.svg"
              alt="Supprimer un programme"
            />
          </Link>
        </div>
      </td>
    </tr>
  );
}
