import { useWindowSize } from "@uidotdev/usehooks";
import { TProgramme } from "../../../Types/Programme.type";
import { TSiteServices } from "../../../Types/SiteService.type";
import { ProgrammeCard } from "./ProgrammeCard";
import { useLayoutEffect, useRef, useState } from "react";

interface IProgrammeRondeProps {
  programs: TProgramme[];
  service: TSiteServices[];
  position: {
    count: number;
    marge: number;
    offset: number;
  };
  setCarouselHeight: (val: number) => void;
}
export function ProgrammeRonde({
  programs,
  service,
  position,
  setCarouselHeight,
}: IProgrammeRondeProps) {
  const { width } = useWindowSize();

  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);

  const onLoding = () => {
    let result = 0;
    for (
      let i = 0;
      i < (ref.current ? ref.current.children.length : 0);
      i += 1
    ) {
      if (
        ref.current &&
        ref.current.children &&
        ref.current.children.item(i) !== null
      ) {
        ref.current.children.item(i)
        if (result < ref.current.children.item(i)!.clientHeight) {
          result = ref.current.children.item(i)!.clientHeight;
        }
      }
    }
    if (result !== height) {
      setHeight(result);
    }
  }

  useLayoutEffect(() => {
    let result = 0;
    for (
      let i = 0;
      i < (ref.current ? ref.current.children.length : 0);
      i += 1
    ) {
      if (
        ref.current &&
        ref.current.children &&
        ref.current.children.item(i) !== null
      ) {
        ref.current.children.item(i)
        if (result < ref.current.children.item(i)!.clientHeight) {
          result = ref.current.children.item(i)!.clientHeight;
        }
      }
    }
    if (result !== height) {
      setHeight(result);
    }
  }, [ref.current?.children.length, width, height]);

  useLayoutEffect(() => {
    setCarouselHeight(height);
  }, [height, setCarouselHeight]);

  return (
    <div className="ronde" ref={ref} onLoad={onLoding}>
      {programs
        .map((program, i) => (
          <ProgrammeCard
            style={{
              left:
                (i - position.offset) * 320 +
                (i - position.offset + 1) * position.marge,
              color: "black",
            }}
            programme={program}
            key={i}
            transitionTime={Number(
              service.filter(
                (item) => item.key === "global_photo_time_transition"
              ).length > 0
                ? service.filter(
                    (item) => item.key === "global_photo_time_transition"
                  )[0].value
                : 800
            )}
            standTime={Number(
              service.filter((item) => item.key === "global_photo_time_stand")
                .length > 0
                ? service.filter(
                    (item) => item.key === "global_photo_time_stand"
                  )[0].value
                : 2200
            )}
          />
        ))}
    </div>
  );
}
