import { useParams } from "react-router-dom";
import { Requester } from "../../../Requester/Requester";
import "../formations.scss";
import { useEffect, useState } from "react";
import { TModule } from "../../../Types/Module.type";
import { TProgramme } from "../../../Types/Programme.type";
import { TSiteServices } from "../../../Types/SiteService.type";
import { ModuleViewInfo } from "./ModuleViewInfo";
import { ModuleViewObjectifs } from "./ModuleViewObjectifs";
import { ModuleViewMethodes } from "./ModuleViewMethodes";
import { ModuleViewEvaluations } from "./ModuleViewEvaluations";
import { ModuleViewEtapes } from "./ModuleViewEtapes";
import { ModuleViewPrerequis } from "./ModuleViewPrerequis";
import { ModuleViewDonnees } from "./ModuleViewDonnees";
import { ModuleViewContact } from "./ModuleViewContact";
import { ProgrammeCard } from "../Programmes/ProgrammeCard";

export function ModuleView() {
  const params = useParams();
  const [module, setModule] = useState<TModule>();
  const [service, setService] = useState<TSiteServices[]>([]);
  const [programs, setPrograms] = useState<TProgramme[]>([]);

  useEffect(() => {
    const getModule = async () => {
      const response = await Requester.modules.getOne(Number(params.id));
      if (response.data) {
        setModule(response.data);
      }
    };
    getModule();
  }, [params.id]);

  useEffect(() => {
    const getPrograms = async () => {
      const response = await Requester.programmes.getAll();
      if (response.data) {
        setPrograms(
          response.data.filter((program) => {
            return (
              module &&
              program.formations.reduce((acc, formation) => {
                return acc || formation.id === module.id;
              }, false)
            );
          })
        );
      }
    };
    getPrograms();
  }, [module]);

  useEffect(() => {
    const getServices = async () => {
      const response = await Requester.siteServices.getAll();
      if (response.data) {
        setService(response.data);
      }
    };
    getServices();
  }, []);

  return (
    <main>
      {module && (
        <>
          <ModuleViewInfo
            module={module}
            transitionTime={Number(
              service.filter(
                (item) => item.key === "global_photo_time_transition"
              ).length > 0
                ? service.filter(
                    (item) => item.key === "global_photo_time_transition"
                  )[0].value
                : 800
            )}
            standTime={Number(
              service.filter((item) => item.key === "global_photo_time_stand")
                .length > 0
                ? service.filter(
                    (item) => item.key === "global_photo_time_stand"
                  )[0].value
                : 2200
            )}
          />
          <div className="d-md-flex high-light-container">
            <div>
              {module.formation_objectifs.length > 0 && (
                <ModuleViewObjectifs module={module} />
              )}
              {module.formation_methods.length > 0 && (
                <ModuleViewMethodes module={module} />
              )}

              {module.formation_evaluations.length > 0 && (
                <ModuleViewEvaluations module={module} />
              )}
            </div>
            {module.formation_steps.length > 0 && (
              <ModuleViewEtapes module={module} />
            )}
          </div>
          {module.formation_prerequests.length > 0 && (
            <ModuleViewPrerequis module={module} />
          )}
          <ModuleViewDonnees module={module} />
          <ModuleViewContact module={module} />

          {programs.length > 0 && (
            <div>
              <h3 style={{ textAlign: "center", margin: "1em" }}>
                {service.filter((item) => item.key === "module_view_accroche")
                  .length > 0
                  ? service.filter(
                      (item) => item.key === "module_view_accroche"
                    )[0].value
                  : ""}
              </h3>
              <div
                className="d-flex flex-wrap justify-content-around"
                style={{ padding: "0 0 5em 0" }}
              >
                {programs.map((programme, i) => (
                  <ProgrammeCard
                  style={{color:"black"}}
                  className="m-2"
                    key={i}
                    programme={programme}
                    transitionTime={Number(
                      service.filter(
                        (item) => item.key === "global_photo_time_transition"
                      ).length > 0
                        ? service.filter(
                            (item) =>
                              item.key === "global_photo_time_transition"
                          )[0].value
                        : 800
                    )}
                    standTime={Number(
                      service.filter(
                        (item) => item.key === "global_photo_time_stand"
                      ).length > 0
                        ? service.filter(
                            (item) => item.key === "global_photo_time_stand"
                          )[0].value
                        : 2200
                    )}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </main>
  );
}
