import { useState, useEffect } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { AdminPhotosView } from "../Photos/AdminPhotosView";
import { TLieu } from "../../../Types/Lieu.type";
import { Requester } from "../../../Requester/Requester";

export function AdminLieuView() {
  const id = useLoaderData() as number;

  const [lieu, setLieu] = useState<TLieu>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillLieu = async () => {
      const response = await Requester.lieux.getOne(id);
      if (response.data) {
        setLieu(response.data);
      } else {
        setLieu(undefined);
        navigate(`/admin/lieux`);
      }
    };

    fillLieu();
  }, [id, navigate]);

  return (
    <>
      {lieu !== undefined && (
        <>
          <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
            <h4>{lieu.title}</h4>
            <div>
              <Link to={`/admin/lieux/update/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-update.svg"
                  alt="Modifier un lieu"
                />
              </Link>

              <Link to={`/admin/lieux/delete/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-delete.svg"
                  alt="Supprimer un lieu"
                />
              </Link>
            </div>
          </div>

          <div className="bambou-border-large main-box">
            <p>{lieu.content}</p>
            <AdminPhotosView photos={lieu.photos} />
          </div>
        </>
      )}
    </>
  );
}
