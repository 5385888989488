import { Link } from "react-router-dom";
import { TProduction } from "../../../../Types/Production.type";

export function ProductionTableItem(props: { production: TProduction }) {
  const { production } = props;

  return (
    <tr className={"compact"}>
    <td>
      <Link to={`/admin/productions/view/${production.id}`}>
        {production.photos.length > 0 && (
          <img
            className={"compact"}
            crossOrigin="anonymous"
            src={`${process.env.REACT_APP_API_URL}/photos/${production.photos[0].path}`}
            alt={production.photos[0].alt}
          />
        )}
      </Link>
    </td>
      <td>
        <Link to={`/admin/productions/view/${production.id}`}>
          {production.name}
        </Link>
      </td>
      <td>
        <Link to={`/admin/productions/view/${production.id}`}>
          {production.locality.title}
        </Link>
      </td>
      <td>
        <Link to={`/admin/productions/view/${production.id}`}>
        {production.product.varietyName} {production.product.name} 
        </Link>
      </td>
      <td>
        <Link to={`/admin/productions/view/${production.id}`}>
          {new Date(production.start).toLocaleDateString()}
        </Link>
      </td>
      <td>
        <Link to={`/admin/productions/view/${production.id}`}>
          {production.end  ? new Date(production.end ).toLocaleDateString() : ""}
        </Link>
      </td>
      <td>
        <div>
          <Link
            to={`/admin/productions/update/${production.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-update.svg"
              alt="Modifier une production"
            />
          </Link>
          <Link
            to={`/admin/productions/delete/${production.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-delete.svg"
              alt="Supprimer une production"
            />
          </Link>
        </div>
      </td>
    </tr>
  );
}
