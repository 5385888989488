import { TModule } from "../../../Types/Module.type";
import { minToHourString } from "../../../Utilities/MinToHourString";

export function ModuleViewDonnees(props: { module: TModule }) {
  const { module } = props;
  return (
    <div className="d-md-flex justify-content-around p-3 high-light-box-g rounded-5">
      <div>
        <h3>Taille de groupe</h3>
        <p>
          <strong>{module.group_size}</strong>
        </p>
      </div>
      <div>
        <h3>Durée</h3>
        <p>
          <strong>{minToHourString(module.duration)}</strong>
        </p>
      </div>
      <div>
        <h3>Prix</h3>
        <p>
          <strong>{module.price}€</strong>
        </p>
      </div>
    </div>
  );
}
