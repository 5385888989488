
import { RequestMethods } from "../Types/RequestMethod.enum";
import { TResponse } from "../Types/Response.type";
import { ICrud } from "./ICrud";
import { RequesterBase } from "./RequesterBase";


export class RequesterCRUD<TData> extends RequesterBase implements ICrud<TData> {

  route : string
  constructor(route : string){
    super();
    this.route = route
  }


  async create(body : TData, token : string ) : Promise<TResponse<TData,string | string[]>>
  {
    const response = await RequesterCRUD.base<TData,string | string[]>(
      this.route,
      RequestMethods.POST ,
      [],
      body,
      token
      )
    return response
  } ;

  async getAll() : Promise<TResponse<TData[],string>>
  {
    const response = await RequesterCRUD.base<TData[],string>(
      this.route,
      RequestMethods.GET ,
      [],
      undefined
      )
    return response
  } ;

  async getOne(id :number) : Promise<TResponse<TData,string>>
  {
    const response = await RequesterCRUD.base<TData,string>(
      this.route,
      RequestMethods.GET ,
      [id],
      undefined
      )
    return response
  } ;

  async update(id :number, body : Partial<TData> , token : string ) : Promise<TResponse<TData,string | string[]>>
  {
    const response = await RequesterCRUD.base<TData,string | string[]>(
      this.route,
      RequestMethods.PATCH ,
      [id],
      body,
      token
      )
    return response
  } ;

  async delete(id :number, token : string  ) : Promise<TResponse<TData,string>>
  {
    const response = await RequesterCRUD.base<TData,string>(
      this.route,
      RequestMethods.DELETE ,
      [id],
      undefined,
      token
      )
    return response
  } ;

}