import { TResponse } from "../Types/Response.type";
import { RequestMethods } from "../Types/RequestMethod.enum";
/**
 * Contient la methode de Requêtes génériques
 *
 * @version v1
 */
export class RequesterBase {
  /**
   * Une Requête générique
   *
   * @type `Data`   Le Type de donnée de la réponse
   *
   * @param url         Le complément d'url
   * @param method  ?   La methode html
   * @param params  ?   Les paramètres
   * @param body    ?   Le corps de la Requête
   * @param token   ?   Le token
   *
   * @returns La réponse à la requête
   *
   * @version v1
   */
  static async base<Data = any, Message = string | string[]>(
    url: string,
    method: RequestMethods = RequestMethods.GET,
    params: (string | number)[] = [],
    body: any = undefined,
    token: string | undefined = undefined,
    files: FileList | undefined = undefined
  ): Promise<TResponse<Data, Message>> {
    const filesBody = new FormData();
    if (body) {
      for (const [ikey, ivalue] of Object.entries(body)) {
        if (ivalue instanceof Array) {
          ivalue.forEach((item, i) => {
            for (const [jkey, jvalue] of Object.entries(item)) {
              filesBody.append(`${ikey}[${i}][${jkey}]`, String(jvalue));
            }
          });
        } else if (ivalue instanceof Date) {
          filesBody.append(ikey, ivalue.toISOString());
        } else {
          filesBody.append(ikey, String(ivalue));
        }
      }
    }
    if (files) {
      for (const file of files) {
        if (file) {
          filesBody.append("files", file, file.name);
        }
      }
    }

    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/${url}${params.map((item) => "/" + String(item))}`,
      {
        method: method,
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: method !== "GET" ? filesBody : undefined,
      }
    );
    const result: TResponse<Data, Message> = await data.json();
    console.log(result.message);

    return result;
  }
}
