import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TActuality } from "../../Types/Actuality.type";
import { Requester } from "../../Requester/Requester";
import { PhotosView } from "../App/components/Photos/PhotosView";
import { TSiteServices } from "../../Types/SiteService.type";

export function ActualiteDetail() {
  const params = useParams();
  const [actu, setActu] = useState<TActuality>();
  const [service, setService] = useState<TSiteServices[]>([]);

  useEffect(() => {
    const getServices = async () => {
      const response = await Requester.siteServices.getAll();
      if (response.data) {
        setService(response.data);
      }
    };
    getServices();
  }, []);

  useEffect(() => {
    const fillActualities = async () => {
      const response = await Requester.actualities.getOne(Number(params.id));
      if (response.data) {
        setActu(response.data);
      }
    };
    fillActualities();
  }, [params.id]);

  return (
    <main>
      {actu && (
        <>
          <div className="p-3 high-light-box-a rounded-5">
            <h2 className="text-center">{actu.title}</h2>
            <div className="d-md-flex ps-3">
              <div>
                <PhotosView
                  photos={actu.photos}
                  transitionTime={Number(
                    service.filter(
                      (item) => item.key === "global_photo_time_transition"
                    ).length > 0
                      ? service.filter(
                        (item) => item.key === "global_photo_time_transition"
                      )[0].value
                      : 800
                  )}
                  standTime={Number(
                    service.filter(
                      (item) => item.key === "global_photo_time_stand"
                    ).length > 0
                      ? service.filter(
                        (item) => item.key === "global_photo_time_stand"
                      )[0].value
                      : 2200
                  )}
                  size={"20em"}
                  maxSize={"75vw"}
                  radius={"2em"} 
                  canReload={true}
                />
                <p>{new Date(actu.date).toLocaleDateString()}</p>
              </div>
              <div className="p-3">
                {actu.content.split("\n").map((item, i) => (
                  <p key={i}>{item}</p>
                ))}
              </div>
            </div>
          </div>
          <div className="d-md-flex">
            {actu.prev && (
              <Link
                to={`/actualite/${actu.prev.id}`}
                className="bambou-border-large px-4 half-box-one"
              >
                <p>Actualite plus récente</p>
                <h5>{actu.prev.title}</h5>
              </Link>
            )}
            {actu.next && (
              <Link
                to={`/actualite/${actu.next.id}`}
                className="bambou-border-large px-4 half-box-two"
              >
                <p>Actualite plus ancienne</p>
                <h5>{actu.next.title}</h5>
              </Link>
            )}
          </div>
        </>
      )}
    </main>
  );
}
