import { useState, useEffect, useContext } from "react";
import { Link, Form, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { DEFAULT_LIEU } from "../../../Constants/lieu.default";
import { Requester } from "../../../Requester/Requester";
import { TLieu } from "../../../Types/Lieu.type";
import { TPhoto } from "../../../Types/Photo.type";
import { AdminPhotosUpdate } from "../Photos/AdminPhotosUpdate";

export function AdminLieuUpdate() {
  const id = useLoaderData() as number;

  const {token} = useContext(TokenContext)
  const [lieu, setLieu] = useState<TLieu>(DEFAULT_LIEU);
  const [filesPhotos, setFilesPhotos] = useState<FileList | null>(null);

  const navigate = useNavigate();
  useEffect(() => {
    const fillLieu = async () => {
      const response = await Requester.lieux.getOne(id);
      if (response.data) {
        setLieu(response.data);
      } else {
        setLieu(DEFAULT_LIEU);
        navigate(`/admin/lieux/actualites`);
      }
    };

    if (id === -1) {
      setLieu(DEFAULT_LIEU);
    } else {
      fillLieu();
    }
  }, [id, navigate]);

  const handleModif = (key: "title" | "content", value: string) => {
    const newValue = { ...lieu };
    newValue[key] = value;
    setLieu(newValue);
  };

  const handleRequest = async () => {
    if (id !== -1) {
      const response = await Requester.lieux.update(
        id,
        lieu,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/lieux/view/${id}`);
    } else {
      const response = await Requester.lieux.create(
        lieu,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/lieux/view/${response.data.id}`);
    }
  };

  const handlePhotos = (value: TPhoto[]) => {
    const newLieu = { ...lieu };
    newLieu.photos = value;
    setLieu(newLieu);
  };

  return (
    <>
    <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
      <h4>{id === -1 ? "Nouveau Lieu" : "Modification d'un Lieu"}</h4>
        <div>
          <Link
            to={`/admin/lieux/view/${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleRequest();
            }}
          >
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-save.svg"
              alt="Enregistrer le lieu"
            />
          </Link>
          <Link to={`/admin/lieux/view/${id}`}>
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-cancel.svg"
              alt="Annuler"
            />
          </Link>
        </div>
      </div>
      <Form className="bambou-border-large main-box">
        <div className="admin-label-input">
          <label htmlFor="lieu-update-titre">Titre</label>
          <input
            type="text"
            id="lieu-update-titre"
            value={lieu.title}
            onChange={(e) => handleModif("title", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="lieu-update-contenu">Contenu</label>
          <textarea
            id="lieu-update-contenu"
            value={lieu.content}
            onChange={(e) => handleModif("content", e.target.value)}
          />
        </div>
        <AdminPhotosUpdate
          photos={lieu.photos}
          setPhotos={handlePhotos}
          files={filesPhotos}
          setFiles={setFilesPhotos}
        />
      </Form>
    </>
  );
}
