import { useParams } from "react-router-dom";
import { Requester } from "../../../Requester/Requester";
import "../formations.scss";
import { useEffect, useState } from "react";
import { TProgramme } from "../../../Types/Programme.type";
import { TSiteServices } from "../../../Types/SiteService.type";
import { ProgrammeViewInfo } from "./ProgrammeViewInfo";
import { ProgrammeViewDonnees } from "./ProgrammeViewDonnees";
import { ProgrammeViewContact } from "./ProgrammeViewContact";
import { ModuleCard } from "../Modules/ModuleCard";

export function ProgrammeView() {
  const params = useParams();
  const [programme, setProgramme] = useState<TProgramme>();
  const [service, setService] = useState<TSiteServices[]>([]);

  useEffect(() => {
    const getProgram = async () => {
      const response = await Requester.programmes.getOne(Number(params.id));
      if (response.data) {
        setProgramme(response.data);
      }
    };
    const getServices = async () => {
      const response = await Requester.siteServices.getAll();
      if (response.data) {
        setService(response.data);
      }
    };
    getProgram();
    getServices();
  }, [params.id]);

  return (
    <main>
      {programme && (
        <>
          <ProgrammeViewInfo
            programme={programme}
            transitionTime={Number(
              service.filter(
                (item) => item.key === "global_photo_time_transition"
              ).length > 0
                ? service.filter(
                    (item) => item.key === "global_photo_time_transition"
                  )[0].value
                : 800
            )}
            standTime={Number(
              service.filter((item) => item.key === "global_photo_time_stand")
                .length > 0
                ? service.filter(
                    (item) => item.key === "global_photo_time_stand"
                  )[0].value
                : 2200
            )}
          />
          <ProgrammeViewDonnees programme={programme} />
          <ProgrammeViewContact programme={programme} />
          <div>
            <h3 style={{ textAlign: "center", margin: "1em" }}>
              {service.filter((item) => item.key === "program_view_accroche")
                .length > 0
                ? service.filter(
                    (item) => item.key === "program_view_accroche"
                  )[0].value
                : ""}
            </h3>
            <div
              className="d-flex flex-wrap justify-content-around"
              style={{ padding: "0 0 5em 0" }}
            >
              {programme.formations.map((module, i) => (
                <ModuleCard
                  style={{color:"black"}}
                  key={i}
                  module={module}
                  transitionTime={Number(
                    service.filter(
                      (item) => item.key === "global_photo_time_transition"
                    ).length > 0
                      ? service.filter(
                          (item) => item.key === "global_photo_time_transition"
                        )[0].value
                      : 800
                  )}
                  standTime={Number(
                    service.filter(
                      (item) => item.key === "global_photo_time_stand"
                    ).length > 0
                      ? service.filter(
                          (item) => item.key === "global_photo_time_stand"
                        )[0].value
                      : 2200
                  )}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </main>
  );
}
