
import { RouterProvider } from 'react-router-dom';
import { ROUTER } from './Router';
import "./App.scss"

function App() {

  return (
    <div className="App d-flex flex-column">
      <RouterProvider router={ROUTER} />
    </div>
  );
}

export default App;
