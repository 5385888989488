
import { Table } from "react-bootstrap";
import { TContact } from "../../../../Types/Contact.type";


interface IContactViewProps {
  values: TContact[];
}
export function ContactView({ values }: IContactViewProps) {

  return (
    <>
    <hr />
    <h5>Contacts</h5>
    <Table striped>
      <thead>
        <tr>
          <th>
            <strong>Nom</strong>
          </th>
          <th>
            <strong>Mail</strong>
          </th>
          <th>
            <strong>Téléphone</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        {values.map((contact, i) => (
          <tr key={i}>
            <td className="px-3">
              {contact.firstName} {contact.lastName}
            </td>
            <td className="px-3">{contact.mail}</td>
            <td className="px-3 col-medium">{contact.phone}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    </>
  );
}
