import { useState, useEffect } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { AdminPhotosView } from "../Photos/AdminPhotosView";
import { TProduction } from "../../../Types/Production.type";
import { Requester } from "../../../Requester/Requester";

export function AdminProductionView() {
  const id = useLoaderData() as number;

  const [production, setProction] = useState<TProduction>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillProduction = async () => {
      const response = await Requester.productions.getOne(id);
      if (response.data) {
        setProction(response.data);
      } else {
        setProction(undefined);
        navigate(`/admin/productions`);
      }
    };

    fillProduction();
  }, [id, navigate]);

  return (
    <>
      {production !== undefined && (
        <>
          <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
            <h4>{production.name}</h4>
            <div>
              <Link to={`/admin/productions/update/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-update.svg"
                  alt="Modifier une production"
                />
              </Link>

              <Link to={`/admin/productions/delete/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-delete.svg"
                  alt="Supprimer une production"
                />
              </Link>
            </div>
          </div>

          <div className="bambou-border-large main-box">
            <p>{production.locality.title}</p>
            <p>{production.product.name}</p>
            <p>{production.product.varietyName}</p>
            <p>{production.description}</p>
            <p>Date de semie {new Date(production.start).toLocaleString()}</p>
            <p>Nombre de semies {production.startquantity}</p>

            {production?.end && (
              <div>
                <p>
                  Date de récolte {new Date(production.end).toLocaleString()}
                </p>
                <p>Récolte {production.endquantity}</p>
              </div>
            )}
            <AdminPhotosView photos={production.photos} />
          </div>
        </>
      )}
    </>
  );
}
