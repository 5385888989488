import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { TLieu } from "../../../Types/Lieu.type";
import { Requester } from "../../../Requester/Requester";

export function AdminLieux() {
  const [lieux, setLieux] = useState<TLieu[]>([]);
  const location = useLocation();

  useEffect(() => {
    const fillLieux = async () => {
      const response = await Requester.lieux.getAll();
      if (response.data) {
        setLieux(response.data);
      }
    };
    fillLieux();
  }, [location]);

  return (
    <nav>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h3>Gestion des Lieux</h3>
        <Link to={`/admin/lieux/new`} className="icon-rondelle">
          <img
            src="/bordures/icon-rondelle-add.svg"
            alt="Ajout d'un lieu"
          />
        </Link>
      </div>
      <div className="bambou-border-large main-box">
        <ul>
          {lieux.map((item, i) => (
            <li key={i}>
              <Link to={`/admin/lieux/view/${item.id}`}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}
