import { useState, useEffect, useContext } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { TProduit } from "../../../Types/Produit.type";
import { Requester } from "../../../Requester/Requester";

export function AdminProduitDelete() {
  const id = useLoaderData() as number;

  const [produit, setProduit] = useState<TProduit>();

  const {token} = useContext(TokenContext)
  const navigate = useNavigate();
  useEffect(() => {
    const fillProduit = async () => {
      const response = await Requester.produits.getOne(id);
      if (response.data) {
        setProduit(response.data);
      } else {
        setProduit(undefined);
        navigate(`/admin/produits`);
      }
    };

    fillProduit();
  }, [id, navigate]);

  const handleRequest = async () => {
    const response = await Requester.produits.delete(id,token);
    await Promise.all([response]);
    navigate(`/admin/produits`);
  };

  return (
    <>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h4>Suppression d'un Produit</h4>
      </div>
      <div className="bambou-border-large main-box">
        <p>Etes-vous sur de vouloir supprimer {produit?.name}</p>

        <Link
          className="rondelle-border"
          to={`/admin/produits/view/${id}`}
          onClick={(e) => {
            e.preventDefault();
            handleRequest();
          }}
        >
          <div>Oui</div>
        </Link>
        <Link className="rondelle-border" to={`/admin/produits/view/${id}`}>
          <div>Non</div>
        </Link>
      </div>
    </>
  );
}
