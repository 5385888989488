import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Requester } from "../../../../Requester/Requester";
import { TContact } from "../../../../Types/Contact.type";

function sortContacts(a: TContact, b: TContact) {
  const aFull = `${a.firstName} ${a.lastName}`;
  const bFull = `${b.firstName} ${b.lastName}`;
  if (aFull < bFull) return -1;
  if (aFull > bFull) return 1;
  return 0;
}

interface IContactUpdateProps {
  values: TContact[];
  setValues: (val: TContact[]) => void;
}
export function ContactUpdate({ values, setValues }: IContactUpdateProps) {
  const [allContacts, setAllContacts] = useState<TContact[]>([]);
  const [restContacts, setRestContacts] = useState<TContact[]>([]);
  const [selectedContact, setSelectedContact] = useState<TContact>();

  useEffect(() => {
    const fillContacts = async () => {
      const responce = await Requester.contacts.getAll();
      responce.data.sort(sortContacts);
      setAllContacts(responce.data);
    };

    fillContacts();
  }, []);

  useEffect(() => {
    const newRest = allContacts
      .filter(
        (contactA) =>
          !values
            .reduce((acc: number[], contactB) => [...acc, contactB.id], [])
            .includes(contactA.id)
      )
      .sort(sortContacts);

    setRestContacts(newRest);
    setSelectedContact(newRest.length > 0 ? newRest[0] : undefined);
  }, [allContacts, values]);

  const addContact = () => {
    setValues([...values, selectedContact!].sort(sortContacts));
  };
  const suppContact = (id: number) => {
    setValues(values.filter((item) => item.id !== id).sort(sortContacts));
  };

  return (
    <>
      <hr />
      <h5>Contacts</h5>
      <Table striped>
        <thead>
          <tr>
            <th className="col-button"></th>
            <th>
              <strong>Nom</strong>
            </th>
            <th>
              <strong>Mail</strong>
            </th>
            <th>
              <strong>Téléphone</strong>
            </th>
            <th className="col-button"></th>
          </tr>
        </thead>
        <tbody>
          {restContacts.length > 0 && (
            <>
              <tr>
                <td>
                  {selectedContact !== undefined && (
                    <button
                      onClick={addContact}
                      className="icon-rondelle border-0 bg-transparent"
                    >
                      <img
                        className="icon-table"
                        src="/bordures/icon-rondelle-add.svg"
                        alt="Ajouter un contact"
                      />
                    </button>
                  )}
                </td>
                <td>
                  <select
                    id="module-update-contact"
                    className="px-1"
                    value={selectedContact?.id}
                    onChange={(e) =>
                      setSelectedContact(
                        restContacts.filter(
                          (item) => item.id === Number(e.target.value)
                        )[0]
                      )
                    }
                  >
                    {restContacts.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.firstName} {item.lastName}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-3">{selectedContact?.mail}</td>
                <td className="px-3">{selectedContact?.phone}</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <hr />
                </td>
              </tr>
            </>
          )}
          {values.map((contact, i) => (
            <tr key={i}>
              <td></td>
              <td className="px-3">
                {contact.firstName} {contact.lastName}
              </td>
              <td className="px-3">{contact.mail}</td>
              <td className="px-3">{contact.phone}</td>
              <td>
                <button
                  onClick={() => suppContact(contact.id)}
                  className="icon-rondelle border-0 bg-transparent"
                >
                  <img
                    className="icon-table"
                    src="/bordures/icon-rondelle-delete.svg"
                    alt="Supprimer un contact"
                  />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
