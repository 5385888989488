import { useState, useEffect } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { AdminPhotosView } from "../Photos/AdminPhotosView";
import { TProduit } from "../../../Types/Produit.type";
import { Requester } from "../../../Requester/Requester";

export function AdminProduitView() {
  const id = useLoaderData() as number;

  const [produit, setProduit] = useState<TProduit>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillProduit = async () => {
      const response = await Requester.produits.getOne(id);
      if (response.data) {
        setProduit(response.data);
      } else {
        setProduit(undefined);
        navigate(`/admin/produits`);
      }
    };

    fillProduit();
  }, [id, navigate]);

  return (
    <>
      {produit !== undefined && (
        <>
          <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
            <h4>
              {produit.varietyName} {produit.name}
            </h4>
            <div>
              <Link
                to={`/admin/produits/update/${id}`}
              >
                <img
                className="icon-rondelle"
                  src="/bordures/icon-rondelle-update.svg"
                  alt="Modifier un produit"
                />
              </Link>

              <Link
                to={`/admin/produits/delete/${id}`}
              >
                <img
                className="icon-rondelle"
                  src="/bordures/icon-rondelle-delete.svg"
                  alt="Supprimer un produit"
                />
              </Link>
            </div>
          </div>

          <div className="bambou-border-large main-box">
            <p>{produit.description}</p>
            <p>Quantité : {produit.quantity}kg</p>
            <p>Prix : {produit.price}€/kg</p>
            <AdminPhotosView photos={produit.photos} />
          </div>
        </>
      )}
    </>
  );
}
