import { useState, useEffect, useContext } from "react";
import { TokenContext } from "../TokenContext";
import { Requester } from "../../../Requester/Requester";
import { TSiteServices } from "../../../Types/SiteService.type";
export function AdminSiteServices() {
  const { token } = useContext(TokenContext);
  const [services, setServices] = useState<TSiteServices[]>([]);
  const [updateServices, setUpdateServices] = useState<TSiteServices[]>([]);

  useEffect(() => {
    const fillServices = async () => {
      const response = await Requester.siteServices.getAll();
      if (response.data) {
        setServices([...response.data].map(item=> {return {...item}}));
        setUpdateServices([...response.data].map(item=> {return {...item}}));
      }
    };
    fillServices();
  }, []);

  const handleChange = (index: number, value: string) => {
    const newService = [...updateServices];
    newService[index].value = value;
    setUpdateServices(newService);
  };

  const handleRequest = async (index: number) => {
    const response = await Requester.siteServices.update(
      updateServices[index].key,
      updateServices[index],
      token
    );
    if (response.data) {
      const newServices = [...services];
      newServices[index].value = response.data.value;
      setServices(newServices);

      const newUpdateServices = [...updateServices];
      newUpdateServices[index].value = response.data.value;
      setUpdateServices(newUpdateServices);
    }
  };
  return (
    <div>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h3>Gestion des Textes du site</h3>
      </div>

      <div className="bambou-border-large main-box">
        {updateServices.map((item, i, arr) => (
          <div key={i} className="d-flex justify-content-between">
            <div className="admin-label-input flex-fill">
              {(i === 0 ||
                arr[i - 1].description.split(" : ")[0] !==
                  item.description.split(" : ")[0]) && (
                <h4>{item.description.split(" : ")[0]}</h4>
              )}
              <label htmlFor={`input-${item.key}`}>
                {item.description.split(" : ")[1]}
              </label>
              <textarea
                id={`input-${item.key}`}
                className="mini"
                value={item.value}
                onChange={(e) => handleChange(i, e.target.value)}
              />
            </div>
            {item.value !== services[i].value && (
              <button
                className="align-self-end"
                style={{
                  background: "none",
                  border: "none",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleRequest(i);
                }}
              >
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-save.svg"
                  alt="Enregistrer l'actualité"
                />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
