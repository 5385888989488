
import { RequestMethods } from "../Types/RequestMethod.enum";
import { RequesterBase } from "./RequesterBase";

export class RequesterVarieties extends RequesterBase {



  async getAll() : Promise<string[]>
  {
    const response = await RequesterVarieties.base<{id : number, name : string}[],string>(
      "varieties",
      RequestMethods.GET ,
      []
      )
    return response.data.map(item => item.name)
  } ;

}