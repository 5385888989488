import { Link } from "react-router-dom";
import { TContact } from "../../../../Types/Contact.type";

export function ContactTableItem(props: { contact: TContact }) {
  const { contact } = props;

  return (
    <tr className={"compact"}>
      <td>
        <Link to={`/admin/contacts/view/${contact.id}`}>
          {contact.firstName} {contact.lastName}
        </Link>
      </td>
      <td>
        <Link to={`/admin/contacts/view/${contact.id}`}>
          {contact.mail}
        </Link>
      </td>
      <td>
        <Link to={`/admin/contacts/view/${contact.id}`}>
          {contact.phone}
        </Link>
      </td>
      <td>
        <div>
          <Link
            to={`/admin/contacts/update/${contact.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-update.svg"
              alt="Modifier un contact"
            />
          </Link>
          <Link
            to={`/admin/contacts/delete/${contact.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-delete.svg"
              alt="Supprimer un contact"
            />
          </Link>
        </div>
      </td>
    </tr>
  );
}
