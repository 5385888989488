import { useState, useEffect, useContext } from "react";
import { Link, Form, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { DEFAULT_ACTUALITE } from "../../../Constants/actualite.default";
import { Requester } from "../../../Requester/Requester";
import { TActuality } from "../../../Types/Actuality.type";
import { TPhoto } from "../../../Types/Photo.type";
import { AdminPhotosUpdate } from "../Photos/AdminPhotosUpdate";

export function AdminActualiteUpdate() {
  const id = useLoaderData() as number;

  const {token} = useContext(TokenContext)
  const [actuality, setActuality] = useState<TActuality>(DEFAULT_ACTUALITE);
  const [filesPhotos, setFilesPhotos] = useState<FileList | null>(null);

  const navigate = useNavigate();
  useEffect(() => {
    const fillActualitie = async () => {
      const response = await Requester.actualities.getOne(id);
      if (response.data) {
        setActuality(response.data);
      } else {
        setActuality(DEFAULT_ACTUALITE);
        navigate(`/admin/actualites`);
      }
    };

    if (id === -1) {
      setActuality(DEFAULT_ACTUALITE);
    } else {
      fillActualitie();
    }
  }, [id, navigate]);

  const handleModif = (key: "title" | "content" | "date", value: string) => {
    const newValue = { ...actuality };
    newValue[key] = value;
    setActuality(newValue);
  };

  const handleRequest = async () => {
    if (id !== -1) {
      const response = await Requester.actualities.update(
        id,
        actuality,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/actualites/view/${id}`);
    } else {
      const response = await Requester.actualities.create(
        actuality,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/actualites/view/${response.data.id}`);
    }
  };
  const handlePhotos = (value: TPhoto[]) => {
    const newActuality = { ...actuality };
    newActuality.photos = value;
    setActuality(newActuality);
  };

  return (
    <>
    <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
      <h4>
        {id === -1 ? "Nouvelle actualité" : "Modification d'une actualité"}
      </h4>
        <div>
          <Link
            to={`/admin/actualites/view/${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleRequest();
            }}
          >
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-save.svg"
              alt="Enregistrer l'actualité"
            />
          </Link>
          <Link to={`/admin/actualites/view/${id}`}>
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-cancel.svg"
              alt="Annuler"
            />
          </Link>
        </div>
      </div>
      <Form className="bambou-border-large main-box">
        <div className="admin-label-input">
          <label htmlFor="actualite-update-titre">Titre</label>
          <input
            type="text"
            id="actualite-update-titre"
            value={actuality.title}
            onChange={(e) => handleModif("title", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="actualite-update-contenu">Contenu</label>
          <textarea
            id="actualite-update-contenu"
            value={actuality.content}
            onChange={(e) => handleModif("content", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="actualite-update-date">Date</label>
          <input
            type="date"
            id="actualite-update-date"
            value={actuality.date
              .split("")
              .filter((_, i) => i < 10)
              .join("")}
            onChange={(e) => handleModif("date", e.target.value)}
          />
        </div>
        <AdminPhotosUpdate
          photos={actuality.photos}
          setPhotos={handlePhotos}
          files={filesPhotos}
          setFiles={setFilesPhotos}
        />
      </Form>
    </>
  );
}
