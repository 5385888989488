import { useState, useEffect, useContext } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { TProduction } from "../../../Types/Production.type";
import { Requester } from "../../../Requester/Requester";

export function AdminProductionDelete() {
  const id = useLoaderData() as number;

  const {token} = useContext(TokenContext)
  const [production, setProduction] = useState<TProduction>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillProduction = async () => {
      const response = await Requester.productions.getOne(id);
      if (response.data) {
        setProduction(response.data);
      } else {
        setProduction(undefined);
        navigate(`/admin/productions`);
      }
    };

    fillProduction();
  }, [id, navigate]);

  const handleRequest = async () => {
    const response = await Requester.productions.delete(id,token);
    await Promise.all([response]);
    navigate(`/admin/productions`);
  };

  return (
    <>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h4>Suppression d'une Production</h4>
      </div>
      <div className="bambou-border-large main-box">
        <p>Etes-vous sur de vouloir supprimer {production?.name}</p>
        <Link
          className="rondelle-border"
          to={`/admin/productions/view/${id}`}
          onClick={(e) => {
            e.preventDefault();
            handleRequest();
          }}
        >
          <div>Oui</div>
        </Link>
        <Link className="rondelle-border" to={`/admin/productions/view/${id}`}>
          <div>Non</div>
        </Link>
      </div>
    </>
  );
}
