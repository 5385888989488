import { LoaderFunctionArgs, createBrowserRouter } from "react-router-dom";
import { Accueil } from "../Accueil/Accueil";
import { Formations } from "../Formations/Formations";
import Root from "./components/Header/Header";
import ErrorPage from "./error";
import { ModuleView } from "../Formations/Modules/ModuleView";
import { ActualiteDetail } from "../Accueil/ActualiteDetail";
import { ProgrammeView } from "../Formations/Programmes/ProgrammeView";
import { AdminActualiteDelete } from "../Admin/Actualites/AdminActualiteDelete";
import { AdminActualiteUpdate } from "../Admin/Actualites/AdminActualiteUpdate";
import { AdminActualiteView } from "../Admin/Actualites/AdminActualiteView";
import { AdminActualites } from "../Admin/Actualites/AdminActualites";
import { Admin } from "../Admin/Admin";
import { AdminBoard } from "../Admin/AdminBoard";
import { AdminContactDelete } from "../Admin/Contacts/AdminContactDelete";
import { AdminContactUpdate } from "../Admin/Contacts/AdminContactUpdate";
import { AdminContactView } from "../Admin/Contacts/AdminContactView";
import { AdminContacts } from "../Admin/Contacts/AdminContacts";
import { AdminLieuDelete } from "../Admin/Lieux/AdminLieuDelete";
import { AdminLieuUpdate } from "../Admin/Lieux/AdminLieuUpdate";
import { AdminLieuView } from "../Admin/Lieux/AdminLieuView";
import { AdminLieux } from "../Admin/Lieux/AdminLieux";
import { AdminModuleDelete } from "../Admin/Modules/AdminModuleDelete";
import { AdminModuleUpdate } from "../Admin/Modules/AdminModuleUpdate";
import { AdminModuleView } from "../Admin/Modules/AdminModuleView";
import { AdminModules } from "../Admin/Modules/AdminModules";
import { AdminProductionDelete } from "../Admin/Productions/AdminProductionDelete";
import { AdminProductionUpdate } from "../Admin/Productions/AdminProductionUpdate";
import { AdminProductionView } from "../Admin/Productions/AdminProductionView";
import { AdminProductions } from "../Admin/Productions/AdminProductions";
import { AdminProduitDelete } from "../Admin/Produits/AdminProduitDelete";
import { AdminProduitUpdate } from "../Admin/Produits/AdminProduitUpdate";
import { AdminProduitView } from "../Admin/Produits/AdminProduitView";
import { AdminProduits } from "../Admin/Produits/AdminProduits";
import { AdminProgrammeDelete } from "../Admin/Programmes/AdminProgrammeDelete";
import { AdminProgrammeUpdate } from "../Admin/Programmes/AdminProgrammeUpdate";
import { AdminProgrammeView } from "../Admin/Programmes/AdminProgrammeView";
import { AdminProgrammes } from "../Admin/Programmes/AdminProgrammes";
import { AdminSiteServices } from "../Admin/SiteServices/AdminSiteServices";


const newLoader = (args: LoaderFunctionArgs) => -1;
const idLoader = (args: LoaderFunctionArgs | { params: { id: string } }) => {
  return Number(args.params.id) || -1
};

export const ROUTER = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Accueil />,
      },
      {
        path: "actualite/:id",
        element:<ActualiteDetail/>
      },
      {
        path: "modules/",
        children:[
          {
            path: "",
            element: <Formations />,
          },
          {
            path: ":id",
            element: <ModuleView />
          }
        ]
      },
      {
        path: "programmes/:id",
        element: <ProgrammeView />
      },
/* 
      {
        path: "ferme/",
        element: <Ferme />,
      },
      {
        path: "produits/",
        element: <Produits />,
      },
       */
      {
        path: "/admin",
        element: <Admin />,
        children: [
          {
            path: "",
            element : <AdminBoard/>
          },
          {
            path: "site-services/",
            element: <AdminSiteServices />,
          },
          {
            path: "contacts/",
            children: [
              {
                path: "",
                element: <AdminContacts />
              },
              {
                path: "new",
                element: <AdminContactUpdate />,
                loader: newLoader
              },
              {
                path: "view/:id",
                element: <AdminContactView />,
                loader: idLoader
              },
              {
                path: "update/:id",
                element: <AdminContactUpdate />,
                loader: idLoader
              },
              {
                path: "delete/:id",
                element: <AdminContactDelete />,
                loader: idLoader
              }
            ],
          },
          {
            path: "actualites/",
            children: [
              {
                path: "",
                element: <AdminActualites />
              },
              {
                path: "new",
                element: <AdminActualiteUpdate />,
                loader: newLoader
              },
              {
                path: "view/:id",
                element: <AdminActualiteView />,
                loader: idLoader
              },
              {
                path: "update/:id",
                element: <AdminActualiteUpdate />,
                loader: idLoader
              },
              {
                path: "delete/:id",
                element: <AdminActualiteDelete />,
                loader: idLoader
              }
            ],
          },
          {
            path: "modules/",
            children: [
              {
                path: "",
                element: <AdminModules />
              },
              {
                path: "new",
                element: <AdminModuleUpdate />,
                loader: newLoader
              },
              {
                path: "view/:id",
                element: <AdminModuleView />,
                loader: idLoader
              },
              {
                path: "update/:id",
                element: <AdminModuleUpdate />,
                loader: idLoader
              },
              {
                path: "delete/:id",
                element: <AdminModuleDelete />,
                loader: idLoader
              }
            ],
          },
          {
            path: "programmes/",
            children: [
              {
                path: "",
                element: <AdminProgrammes />
              },
              {
                path: "new",
                element: <AdminProgrammeUpdate />,
                loader: newLoader
              },
              {
                path: "view/:id",
                element: <AdminProgrammeView />,
                loader: idLoader
              },
              {
                path: "update/:id",
                element: <AdminProgrammeUpdate />,
                loader: idLoader
              },
              {
                path: "delete/:id",
                element: <AdminProgrammeDelete />,
                loader: idLoader
              }
            ],
          },
          {
            path: "produits/",
            children: [
              {
                path: "",
                element: <AdminProduits />,
              },
              {
                path: "new",
                element: <AdminProduitUpdate />,
                loader: newLoader
              },
              {
                path: "view/:id",
                element: <AdminProduitView />,
                loader: idLoader
              },
              {
                path: "update/:id",
                element: <AdminProduitUpdate />,
                loader: idLoader
              },
              {
                path: "delete/:id",
                element: <AdminProduitDelete />,
                loader: idLoader
              }
            ],
          },
          {
            path: "lieux/",
            children: [
              {
                path: "",
                element: <AdminLieux />
              },
              {
                path: "new",
                element: <AdminLieuUpdate />,
                loader: newLoader
              },
              {
                path: "view/:id",
                element: <AdminLieuView />,
                loader: idLoader
              },
              {
                path: "update/:id",
                element: <AdminLieuUpdate />,
                loader: idLoader
              },
              {
                path: "delete/:id",
                element: <AdminLieuDelete />,
                loader: idLoader
              }
            ],
          },
          {
            path: "productions/",
            children: [
              {
                path: "",
                element: <AdminProductions />,
              },
              {
                path: "new",
                element: <AdminProductionUpdate />,
                loader: newLoader
              },
              {
                path: "view/:id",
                element: <AdminProductionView />,
                loader: idLoader
              },
              {
                path: "update/:id",
                element: <AdminProductionUpdate />,
                loader: idLoader
              },
              {
                path: "delete/:id",
                element: <AdminProductionDelete />,
                loader: idLoader
              }
            ],
          },
        ],
      },
    ],
  },
])