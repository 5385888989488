import { Link } from "react-router-dom";
import { TModule } from "../../../Types/Module.type";
import { minToHourString } from "../../../Utilities/MinToHourString";
import { PhotosView } from "../../App/components/Photos/PhotosView";

interface IModuleCardProps {
  module: TModule;
  transitionTime: number;
  standTime: number;
  style?: React.CSSProperties;
}
export function ModuleCard({
  module,
  transitionTime,
  standTime,
  style,
}: IModuleCardProps) {
  return (
    <Link
      to={`/modules/${module.id}`}
      className="d-block text-center item-card module m-2"
      style={style}
    >
      <div>
        <div>
          <h5 className="m-0 p-2">{module.title}</h5>
          <div className="d-flex d-md-block">
            <div className="flex-fill d-flex flex-column flex-md-row justify-content-evenly align-content-evenly item-card-data">
              <div className="w-100">
                <p>
                  {minToHourString(module.duration)}
                </p>
              </div>
              <div className="w-100">
                <p>{module.price}€</p>
              </div>
            </div>
            <PhotosView
              photos={module.photos}
              transitionTime={transitionTime}
              standTime={standTime}
              size={"100%"}
              maxSize={"100%"}
              radius={"0"}
            />
          </div>
        </div>
      </div>
    </Link>
  );
}
