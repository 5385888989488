import { useState, useEffect, useContext } from "react";
import { Link, Form, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { DEFAULT_PRODUIT } from "../../../Constants/produit.default";
import { Requester } from "../../../Requester/Requester";
import { TPhoto } from "../../../Types/Photo.type";
import { TProduit } from "../../../Types/Produit.type";
import { AdminPhotosUpdate } from "../Photos/AdminPhotosUpdate";

export function AdminProduitUpdate() {
  const id = useLoaderData() as number;

  const {token} = useContext(TokenContext)
  const [produit, setProduit] = useState<TProduit>(DEFAULT_PRODUIT);
  const [varieties, setVarieties] = useState<string[]>([]);
  const [filesPhotos, setFilesPhotos] = useState<FileList | null>(null);

  const navigate = useNavigate();
  useEffect(() => {
    const fillProduit = async () => {
      const response = await Requester.produits.getOne(id);
      if (response.data) {
        setProduit(response.data);
      } else {
        setProduit(DEFAULT_PRODUIT);
        navigate(`/admin/actualites`);
      }
    };
    const fillVarieties = async () => {
      setVarieties(await Requester.varieties.getAll());
    };
    fillVarieties();
    if (id === -1) {
      setProduit(DEFAULT_PRODUIT);
    } else {
      fillProduit();
    }
  }, [id, navigate]);

  const handleModif = (
    key: "name" | "varietyName" | "description" | "price" | "quantity",
    value: string
  ) => {
    const newValue = { ...produit };
    if (key === "price" || key === "quantity") {
      newValue[key] = Number(value);
    } else {
      newValue[key] = value;
    }
    setProduit(newValue);
  };

  const handleRequest = async () => {
    if (id !== -1) {
      const response = await Requester.produits.update(
        id,
        produit,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/produits/view/${id}`);
    } else {
      const response = await Requester.produits.create(
        produit,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/produits/view/${response.data.id}`);
    }
  };

  const handlePhotos = (value: TPhoto[]) => {
    const newProduit = { ...produit };
    newProduit.photos = value;
    setProduit(newProduit);
  };

  return (
    <>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h4>{id === -1 ? "Nouveau produit" : "Modification d'un produit"}</h4>
        <div>
          <Link
            to={`/admin/produits/view/${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleRequest();
            }}
          >
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-save.svg"
              alt="Enregistrer le produit"
            />
          </Link>
          <Link to={`/admin/produits/view/${id}`}>
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-cancel.svg"
              alt="Annuler"
            />
          </Link>
        </div>
      </div>
      <Form className="bambou-border-large main-box">
        <div className="admin-label-input">
          <label htmlFor="produit-update-name">Nom</label>
          <input
            type="text"
            id="produit-update-name"
            value={produit.name}
            onChange={(e) => handleModif("name", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="produit-update-variety">Variété</label>
          <select
            className="mini"
            id="produit-update-variety"
            onChange={(e) =>
              e.target.value && handleModif("varietyName", e.target.value)
            }
          >
            <option key={-1} value=""></option>
            {varieties.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
          <input
            type="text"
            id="produit-update-variety"
            className="with-options"
            value={produit.varietyName}
            onChange={(e) => handleModif("varietyName", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="produit-update-quantity">Quantité</label>
          <input
            type="number"
            id="produit-update-quantity"
            value={produit.quantity}
            onChange={(e) => handleModif("quantity", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="produit-update-price">Prix</label>
          <input
            type="number"
            id="produit-update-price"
            value={produit.price}
            onChange={(e) => handleModif("price", e.target.value)}
          />
        </div>

        <div className="admin-label-input">
          <label htmlFor="produit-update-contenu" style={{ display: "block" }}>
            Description
          </label>
          <textarea
            id="produit-update-contenu"
            value={produit.description}
            onChange={(e) => handleModif("description", e.target.value)}
          />
        </div>
        <AdminPhotosUpdate
          photos={produit.photos}
          setPhotos={handlePhotos}
          files={filesPhotos}
          setFiles={setFilesPhotos}
        />
      </Form>
    </>
  );
}
