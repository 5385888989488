import { useContext, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import { TokenContext } from "./TokenContext";
import { TResponse } from "../../Types/Response.type";

export function AdminLogin() {

  const navigate = useNavigate()

  const {setToken} = useContext(TokenContext)

  const [loginBody, setLoginBody] = useState({
    name : "",
    password : ""
  })

  const [registerBody, setRegisterBody] = useState({
    name : "",
    password : "",
    invitationword : ""
  })

  const [message, setMessage] = useState("")

  const handleUpdateLogin = (key: "name" | "password", value : string) => {
    const newBody = {...loginBody};
    newBody[key] = value
    setLoginBody(newBody)
  } 

  const handleUpdateRegister = (key: "name" | "password" | "invitationword", value : string) => {
    const newBody = {...registerBody};
    newBody[key] = value
    setRegisterBody(newBody)
  } 

  const handleSubmitLogin = async ()=> {
    
    
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/users/login`, 
      {
        method:  "POST" ,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(loginBody),
      }
    );
    const result: TResponse<{token : string},string | string[]> = await data.json();
    if(result.data){
      setToken(result.data.token)
      setMessage("")
      navigate(`/admin/actualites`)
    }
    else {
      setToken("")
      if(typeof result.message === "string"){
        setMessage(result.message)
      }
      else {
        setMessage(result.message.join(". "))
      }
    }
  }

  const handleSubmitRegister = async ()=> {
    
    
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/users/register`, 
      {
        method:  "POST" ,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(registerBody),
        
      }
    );
    const result: TResponse<{token : string},string | string[]> = await data.json();
    if(result.data){
      const newLog = {...loginBody};
      newLog.name = registerBody.name;
      newLog.password = registerBody.password;
      setLoginBody(newLog);
    }
    else {
      if(typeof result.message === "string"){
        setMessage(result.message)
      }
      else {
        setMessage(result.message.join(". "))
      }
    }
  }

  return (
    <div>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h3> Connection</h3>
      </div>

      <Form className="bambou-border-large main-box">
        <div className="admin-label-input">
          <label htmlFor="user-update-login-name">Nom</label>
          <input
            type="text"
            id="user-update-login-name"
            value={loginBody.name}
            onChange={(e) => handleUpdateLogin("name", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="user-update-login-pass">Mot de passe</label>
          <input
            type="password"
            id="user-update-login-pass"
            value={loginBody.password}
            onChange={(e) => handleUpdateLogin("password", e.target.value)}
          />
        </div>
        <p>{message}</p>
        <button onClick={(e)=>{e.preventDefault(); handleSubmitLogin()}} className="p-1 rounded bg-primary">Connection</button>
      </Form>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h3>Ou Enregistrement</h3>
      </div>

      <Form className="bambou-border-large main-box">
        <div className="admin-label-input">
          <label htmlFor="user-update-register-name">Nom</label>
          <input
            type="text"
            id="user-update-register-name"
            value={registerBody.name}
            onChange={(e) => handleUpdateRegister("name", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="user-update-register-pass">Mot de passe</label>
          <input
            type="password"
            id="user-update-register-pass"
            value={registerBody.password}
            onChange={(e) => handleUpdateRegister("password", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="user-update-register-token">Token d'invitation</label>
          <input
            type="password"
            id="user-update-register-token"
            value={registerBody.invitationword}
            onChange={(e) => handleUpdateRegister("invitationword", e.target.value)}
          />
        </div>
        <button onClick={(e)=>{e.preventDefault(); handleSubmitRegister()}} className="p-1 rounded bg-primary">Enregistrement</button>
      </Form>
    </div>
  );
}
