import { useState, useEffect } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { AdminPhotosView } from "../Photos/AdminPhotosView";
import { TActuality } from "../../../Types/Actuality.type";
import { Requester } from "../../../Requester/Requester";

export function AdminActualiteView() {
  const id = useLoaderData() as number;

  const [actualitie, setActualitie] = useState<TActuality>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillActualitie = async () => {
      const response = await Requester.actualities.getOne(id);
      if (response.data) {
        setActualitie(response.data);
      } else {
        setActualitie(undefined);
        navigate(`/admin/actualites`);
      }
    };

    fillActualitie();
  }, [id]);

  return (
    <>
      {actualitie !== undefined && (
        <>
          <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
            <h4>{actualitie.title}</h4>
            <div>
              <Link to={`/admin/actualites/update/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-update.svg"
                  alt="Modifier une actualité"
                />
              </Link>

              <Link to={`/admin/actualites/delete/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-delete.svg"
                  alt="Supprimer une actualité"
                />
              </Link>
            </div>
          </div>
          <div className="bambou-border-large main-box">
            <p>{actualitie.content}</p>
            <p>{actualitie.date.toString()}</p>
            <AdminPhotosView photos={actualitie.photos} />
          </div>
        </>
      )}
    </>
  );
}
