import { useState, useEffect, useContext } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { TProgramme } from "../../../Types/Programme.type";
import { Requester } from "../../../Requester/Requester";

export function AdminProgrammeDelete() {
  const id = useLoaderData() as number;

  const {token} = useContext(TokenContext)
  const [programme, setProgramme] = useState<TProgramme>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillProgramme = async () => {
      const response = await Requester.programmes.getOne(id);
      if (response.data) {
        setProgramme(response.data);
      } else {
        setProgramme(undefined);
        navigate(`/admin/programmes`);
      }
    };

    fillProgramme();
  }, [id, navigate]);

  const handleRequest = async () => {
    const response = await Requester.programmes.delete(id,token);
    await Promise.all([response]);
    navigate(`/admin/programmes`);
  };

  return (
    <>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h4>Suppression d'une Programme</h4>
      </div>
      <div className="bambou-border-large main-box">
        <p>Etes-vous sur de vouloir supprimer {programme?.title}</p>
        <Link
          className="rondelle-border"
          to={`/admin/programmes/view/${id}`}
          onClick={(e) => {
            e.preventDefault();
            handleRequest();
          }}
        >
          <div>Oui</div>
        </Link>
        <Link className="rondelle-border" to={`/admin/programmes/view/${id}`}>
          <div>Non</div>
        </Link>
      </div>
    </>
  );
}
