import { TModule } from "../../../Types/Module.type";

export function ModuleViewContact(props: { module: TModule }) {
  const { module } = props;
  return (
    <div className="d-flex flex-wrap justify-content-around p-3 high-light-box-a rounded-5">
      <img
        style={{ height: "5em" }}
        src="/images/logo_AgorAguets.png"
        alt="Logo AgorAgueuts"
      />
      <img
        className="mb-3"
        style={{ height: "5em" }}
        src="/images/logo-Qualiopi.png"
        alt="Logo Qualiopi"
      />
      {module.contacts.length > 0 && (
        <div>
          <h3>Contactez-nous</h3>
          {module.contacts.map((contact, i) => (
            <div key={i}>
              <p className="m-0">
                {contact.firstName} {contact.lastName} ({contact.phone})
              </p>
              <a className="d-block mb-2" href={`mailto:${contact.mail}`}>
                {contact.mail}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
