import { useEffect, useState } from "react";
import { TPhoto } from "../../../../Types/Photo.type";

interface IPhotosViewProps {
  photos: TPhoto[];
  transitionTime: number;
  standTime: number;
  size : string;
  maxSize : string;
  radius : string;
  canReload? : boolean;
}
export function PhotosView({
  photos,
  transitionTime,
  standTime,
  size,
  maxSize,
  radius,
  canReload,
}: IPhotosViewProps) {
  const [started, setStarted] = useState(false)
  const [listing, setListing] = useState([...photos]);
  const [frame, setFrame] = useState(-1);
  const [backIndex, setBackIndex] = useState(0);
  const [frontIndex, setFrontIndex] = useState(0);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (!started || canReload){
      const newListing = photos
        .reduce((acc: TPhoto[], photo: TPhoto) => {
          if (acc.map((item) => item.path).includes(photo.path)) {
            return acc;
          }
          return [...acc, photo];
        }, [])
        .sort((a, b) => (a.id || 0) - (b.id || 0));
      
      setListing(newListing);
      setBackIndex(0);
      setFrontIndex(newListing.length > 1 ? 1 : 0);
      setFrame(0);
      setStarted(true)
    }
  }, [photos,started,canReload]);

  useEffect(() => {

    if (backIndex !==frontIndex ){
      if (frame % 2 === 0){
        setOpacity(1);
        if (frontIndex + 2 >= listing.length) {
          setFrontIndex(frontIndex + 2 - listing.length)
        }
        else {
          setFrontIndex(frontIndex + 2)
        }
      } else {
        setOpacity(0);
        if (backIndex + 2 >= listing.length) {
          setBackIndex(backIndex + 2 - listing.length)
        }
        else {
          setBackIndex(backIndex + 2)
        }
      }
      
      setTimeout(()=>{setFrame(frame + 1)},standTime + transitionTime);
    }
  }, [frame]);
  return (
    <div
      className="gallery-photo"
      style={{
        height: `min(${size},${maxSize})`,
        width: `min(${size},${maxSize})`,
        minHeight: `min(${size},${maxSize})`,
        minWidth: `min(${size},${maxSize})`,
        borderRadius: radius,
      }}
    >
      { listing.length > 0 && (
        <>
          <img
            style={{
              height: `min(${size},${maxSize})`,
              width: `min(${size},${maxSize})`,
            }}
            src={`${process.env.REACT_APP_API_URL}/photos/${listing[backIndex].path}`}
            alt={listing[backIndex].alt}
          />
          <img
            style={{
              height: `min(${size},${maxSize})`,
              width: `min(${size},${maxSize})`,
              opacity: opacity,
              transition: `opacity ${transitionTime / 1000}s`,
            }}
            src={`${process.env.REACT_APP_API_URL}/photos/${listing[frontIndex].path}`}
            alt={listing[frontIndex].alt}
          />
        </>
      )}
    </div>
  );
}
