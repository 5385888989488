import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "react-bootstrap/esm/Table";
import { ProduitTableItem } from "./Components/ProduitTableItem";
import { Requester } from "../../../Requester/Requester";
import { TProduit } from "../../../Types/Produit.type";

export function AdminProduits() {
  const [produits, setProduits] = useState<TProduit[]>([]);
  const [produitsFilter, setProduitsFilter] = useState<TProduit[]>([]);
  const [searchName, setSearchName] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const fillProduits = async () => {
      const response = await Requester.produits.getAll();
      if (response.data) {
        setProduits(response.data);
      }
    };
    fillProduits();
  }, [location]);

  useEffect(() => {
    setProduitsFilter(
      produits
        .filter((item) =>
          searchName
            ? `${item.varietyName} ${item.name}`
                .toLowerCase()
                .search(searchName.toLowerCase()) > -1
            : true
        )
        .sort((a, b) => {
          let fullA = `${a.varietyName} ${a.name}`;
          let fullB = `${b.varietyName} ${b.name}`;
          if (fullA < fullB) {
            return -1;
          } else if (fullA > fullB) {
            return 1;
          }
          return 0;
        })
    );
  }, [produits, searchName]);

  const handleSearchName = (value: string) => {
    setSearchName(value);
  };

  return (
    <nav>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h3 className="m-0">Gestion des Produits</h3>
        <Link to={`/admin/produits/new`}>
          <img
            className="icon-rondelle"
            src="/bordures/icon-rondelle-add.svg"
            alt="Ajout d'un produit"
          />
        </Link>
      </div>

      <div className="bambou-border-large main-box">
        <Table striped hover>
          <thead>
            <tr>
              <th className={"col-small"}><div>Photos</div></th>
              <th>
                <div><label htmlFor="search-name">Variété et Nom :</label>{" "}
                <input
                  id="search-name"
                  value={searchName}
                  onChange={(e) => handleSearchName(e.target.value)}
                ></input></div>
              </th>
              <th className={"col-small"}><div>Quantité</div></th>
              <th className={"col-small"}><div>Prix</div></th>
              <th className={"col-small"}><div>Actions</div></th>
            </tr>
          </thead>
          <tbody>
            {produitsFilter.map((item, i) => (
              <ProduitTableItem key={i} product={item} />
            ))}
          </tbody>
        </Table>
      </div>
    </nav>
  );
}
