import { Outlet, useLocation } from "react-router-dom";
import "./Header.scss";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import { TContact } from "../../../../Types/Contact.type";
import { useEffect, useState } from "react";
import { Requester } from "../../../../Requester/Requester";

export default function Root() {
  const [contacts, setContacts] = useState<TContact[]>([]);

  const location = useLocation();

  useEffect(() => {
    const getContacts = async () => {
      const response = await Requester.contacts.getAll();
      if (response.data) {
        setContacts(response.data);
      }
    };
    getContacts();
  }, []);

  return (
    <>
      {!location.pathname.search("admin") && (
        <Navbar bg="light" expand="lg" style={{ padding: 0 }}>
          <Container fluid style={{ paddingLeft: 0 }}>
            <LinkContainer
              to={`/`}
              style={{ padding: 0 }}
            >
              <Navbar.Brand>
                <img
                  style={{ height: "2em", margin: 0, padding: "0 1em 0 0" }}
                  src="/images/logo_AgorAguets_sans_titre.png"
                  alt="Logo AgorAgueuts"
                />
                Agor-Aguets
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to={`/`}>
                  <Nav.Link>Accueil</Nav.Link>
                </LinkContainer>
                <LinkContainer
                  to={`/modules`}
                >
                  <Nav.Link>Les Formations</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
      <div className="flex-fill">
        <Outlet />
      </div>
      {!location.pathname.search("admin") && (
        <footer className="mt-3 p-3" style={{ background: "#FFFFFF" }}>
          <div className="d-flex flex-wrap justify-content-center">
            <div className="d-flex align-items-center mx-4 my-2">
              <img
                style={{ height: "5em" }}
                src="/images/logo_AgorAguets.png"
                alt="Logo AgorAgueuts"
              />
            </div>
            <div className="d-flex align-items-center mx-4 my-2">
              <img
                className="mb-3"
                style={{ height: "5em" }}
                src="/images/logo-Qualiopi.png"
                alt="Logo Qualiopi"
              />
            </div>
            <div className="mx-4">
              {contacts.map((contact, i) => (
                <div key={i}>
                  <p className="m-0">
                    {contact.firstName} {contact.lastName} ({contact.phone})
                  </p>
                  <a className="d-block mb-2" href={`mailto:${contact.mail}`}>
                    {contact.mail}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </footer>
      )}
    </>
  );
}
