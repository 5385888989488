import { Requester } from "../../Requester/Requester";
import { useState, useEffect } from "react";
import { TModule } from "../../Types/Module.type";
import { TProgramme } from "../../Types/Programme.type";
import { ModuleCard } from "./Modules/ModuleCard";
import { TSiteServices } from "../../Types/SiteService.type";
import { useWindowSize } from "@uidotdev/usehooks";
import { ProgrammeRonde } from "./Programmes/ProgrammeRonde";

export function Formations() {
  const [modules, setModules] = useState<TModule[]>([]);
  const [programs, setPrograms] = useState<TProgramme[]>([]);
  const [service, setService] = useState<TSiteServices[]>([]);

  const { width } = useWindowSize();
  const [carouselHeight, setcarouselHeight] = useState(0);

  const [carouselPosition, setCarouselPosition] = useState({
    count: Math.max(Math.floor(((width || 0) - 20) / 384), 1),
    marge:
      ((width || 0) -
        20 -
        Math.max(Math.floor(((width || 0) - 20) / 384), 1) * 320) /
      (Math.max(Math.floor(((width || 0) - 20) / 384), 1) + 1),
    offset: 0,
  });

  useEffect(() => {
    const count = Math.min(
      Math.max(Math.floor(((width || 0) - 20) / 384), 1),
      programs.length
    );

    setCarouselPosition({
      count,
      marge: Math.max(((width || 0) - count * 325) / (count + 1), 0),
      offset: 0,
    });
  }, [width, programs]);

  useEffect(() => {
    const getModules = async () => {
      const response = await Requester.modules.getAll();
      if (response.data) {
        setModules(response.data);
      }
    };
    getModules();
  }, []);

  useEffect(() => {
    const getPrograms = async () => {
      const response = await Requester.programmes.getAll();
      if (response.data) {
        setPrograms(response.data);
      }
    };
    getPrograms();
  }, []);

  useEffect(() => {
    const getServices = async () => {
      const response = await Requester.siteServices.getAll();
      if (response.data) {
        setService(response.data);
      }
    };
    getServices();
  }, []);

  const handleCarouselGauche = () => {
    const newPos = { ...carouselPosition };
    if (newPos.offset === 0) {
      newPos.offset = programs.length - newPos.count;
    } else {
      newPos.offset = Math.max(newPos.offset - newPos.count, 0);
    }
    setCarouselPosition(newPos);
  };

  const handleCarouselDroite = () => {
    const newPos = { ...carouselPosition };
    if (newPos.offset === programs.length - newPos.count) {
      newPos.offset = 0;
    } else {
      newPos.offset = Math.min(
        newPos.offset + newPos.count,
        programs.length - newPos.count
      );
    }
    setCarouselPosition(newPos);
  };

  return (
    <main>
      <div className="px-4 py-4 text-center">
        <h2 className="fw-bold">
          {service.filter((item) => item.key === "all_learning_program_title")
            .length > 0
            ? service.filter(
                (item) => item.key === "all_learning_program_title"
              )[0].value
            : ""}
        </h2>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            {service.filter(
              (item) => item.key === "all_learning_program_teaser"
            ).length > 0
              ? service.filter(
                  (item) => item.key === "all_learning_program_teaser"
                )[0].value
              : ""}
          </p>
        </div>
      </div>
      <div
        className=" d-flex justify-content-between carouselle-programmes"
        style={{ height: carouselHeight }}
      >
        {programs.length > carouselPosition.count && (
          <div className="fleche gauche">
            <img
              onClick={handleCarouselGauche}
              className="icon-rondelle"
              src="/bordures/icon-rondelle-gauche.svg"
              alt="Enregistrer l'actualité"
            />
          </div>
        )}
        <ProgrammeRonde
          setCarouselHeight={setcarouselHeight}
          programs={programs}
          service={service}
          position={carouselPosition}
        />
        {programs.length > carouselPosition.count && (
          <div className="fleche droite">
            <img
              onClick={handleCarouselDroite}
              className="icon-rondelle"
              src="/bordures/icon-rondelle-droite.svg"
              alt="Enregistrer l'actualité"
            />
          </div>
        )}
      </div>
      <div className="px-4 py-5 text-center">
        <h2 className="fw-bold">
          {service.filter((item) => item.key === "all_learning_module_title")
            .length > 0
            ? service.filter(
                (item) => item.key === "all_learning_module_title"
              )[0].value
            : ""}
        </h2>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            {service.filter((item) => item.key === "all_learning_module_teaser")
              .length > 0
              ? service.filter(
                  (item) => item.key === "all_learning_module_teaser"
                )[0].value
              : ""}
          </p>
        </div>
      </div>
      <div className="mx-auto d-flex flex-wrap justify-content-center justify-content-sm-evenly">
        {modules.map((module, index) => (
          <ModuleCard
            style={{ color: "black" }}
            module={module}
            key={index}
            transitionTime={Number(
              service.filter(
                (item) => item.key === "global_photo_time_transition"
              ).length > 0
                ? service.filter(
                    (item) => item.key === "global_photo_time_transition"
                  )[0].value
                : 800
            )}
            standTime={Number(
              service.filter((item) => item.key === "global_photo_time_stand")
                .length > 0
                ? service.filter(
                    (item) => item.key === "global_photo_time_stand"
                  )[0].value
                : 2200
            )}
          />
        ))}
      </div>
    </main>
  );
}
