import { useState, useEffect, useContext } from "react";
import { Link, Form, useLoaderData, useNavigate } from "react-router-dom";
import { DEFAULT_CONTACT } from "../../../Constants/contact.default";
import { Requester } from "../../../Requester/Requester";
import { TContact } from "../../../Types/Contact.type";
import { TokenContext } from "../TokenContext";

export function AdminContactUpdate() {
  const id = useLoaderData() as number;

  const { token } = useContext(TokenContext);
  const [contact, setContact] = useState<TContact>(DEFAULT_CONTACT);

  const navigate = useNavigate();
  useEffect(() => {
    const fillContact = async () => {
      const response = await Requester.contacts.getOne(id);
      if (response.data) {
        setContact(response.data);
      } else {
        setContact(DEFAULT_CONTACT);
        navigate(`/admin/contacts`);
      }
    };

    if (id === -1) {
      setContact(DEFAULT_CONTACT);
    } else {
      fillContact();
    }
  }, [id, navigate]);

  const handleModif = (
    key: "firstName" | "lastName" | "mail" | "phone",
    value: string
  ) => {
    const newValue = { ...contact };
    newValue[key] = value;
    setContact(newValue);
  };

  const handleRequest = async () => {
    if (id !== -1) {
      const response = await Requester.contacts.update(
        id,
        contact,
        token
      );
      await Promise.all([response]);
      navigate(`/admin/contacts/view/${id}`);
    } else {
      const response = await Requester.contacts.create(
        contact,
        token
      );
      await Promise.all([response]);
      navigate(`/admin/contacts/view/${response.data.id}`);
    }
  };


  return (
    <>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h4>{id === -1 ? "Nouveau contact" : "Modification d'un contact"}</h4>
        <div>
          <Link
            to={`/admin/contacts/view/${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleRequest();
            }}
          >
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-save.svg"
              alt="Enregistrer le contact"
            />
          </Link>
          <Link to={`/admin/contacts/view/${id}`}>
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-cancel.svg"
              alt="Annuler"
            />
          </Link>
        </div>
      </div>
      <Form className="bambou-border-large main-box">
        <div className="admin-label-input">
          <label htmlFor="contact-update-firstName">Prénom</label>
          <input
            type="text"
            id="contact-update-firstName"
            value={contact.firstName}
            onChange={(e) => handleModif("firstName", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="contact-update-lastName">Nom</label>
          <input
            type="text"
            id="contact-update-lastName"
            value={contact.lastName}
            onChange={(e) => handleModif("lastName", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="contact-update-mail">Mail</label>
          <input
            type="text"
            id="contact-update-mail"
            value={contact.mail}
            onChange={(e) => handleModif("mail", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="contact-update-phone">Téléphone</label>
          <input
            type="text"
            id="contact-update-phone"
            value={contact.phone}
            onChange={(e) => handleModif("phone", e.target.value)}
          />
        </div>
      </Form>
    </>
  );
}
