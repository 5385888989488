import { TActuality } from "../../Types/Actuality.type";
import "./Accueil.scss";
import { useEffect, useState } from "react";
import { Requester } from "../../Requester/Requester";
import ActualiteListCard from "./ListCard/ActualiteListCard_A";
import { TSiteServices } from "../../Types/SiteService.type";

const ITEMBYPAGE = 5;

export function Accueil() {
  const [actualities, setActualities] = useState<TActuality[]>([]);
  const [nextActualities, setNextActualities] = useState<TActuality[]>([]);
  const [nextPage, setNextPage] = useState<number>(3);
  const [service, setService] = useState<TSiteServices[]>([]);

  useEffect(() => {
    const getServices = async () => {
      const response = await Requester.siteServices.getAll();
      if (response.data) {
        setService(response.data);
      }
    };
    getServices();
  }, []);

  useEffect(() => {
    const fillActualities = async () => {
      const response = await Requester.actualities.getAll({
        itemsByPage: ITEMBYPAGE * 2,
        page: 1,
      });
      if (response.data) {
        const sortedData = response.data.sort((a, b) => {
          let adate = new Date(a.date);
          let bdate = new Date(b.date);
          if (adate > bdate) return -1;
          else if (adate < bdate) return 1;
          return 0;
        });
        setActualities(sortedData.filter((_, i) => i < ITEMBYPAGE));
        setNextActualities(sortedData.filter((_, i) => i >= ITEMBYPAGE));
      }
    };
    fillActualities();
  }, []);

  const handleMore = async () => {
    setActualities([...actualities, ...nextActualities]);
    const response = await Requester.actualities.getAll({
      itemsByPage: ITEMBYPAGE,
      page: nextPage,
    });
    if (response.data) {
      const sortedData = response.data.sort((a, b) => {
        let adate = new Date(a.date);
        let bdate = new Date(b.date);
        if (adate > bdate) return -1;
        else if (adate < bdate) return 1;
        return 0;
      });
      setNextActualities(sortedData);
      setNextPage(nextPage + 1);
    }
  };

  return (
    <>
      <div>
        <h2 className="fw-bold text-center m-3">
          {
            service.filter(
              (item) => item.key === "home_wellcome_title"
            ).length > 0
              ? service.filter(
                  (item) => item.key === "home_wellcome_title"
                )[0].value
              : ""
          }
        </h2>
        <p className="text-center m-3">
          {
            service.filter(
              (item) => item.key === "home_wellcome_teaser"
            ).length > 0
              ? service.filter(
                  (item) => item.key === "home_wellcome_teaser"
                )[0].value
              : ""
          }
        </p>
        <h2 className="fw-bold text-center m-3">
          {
            service.filter(
              (item) => item.key === "home_actuality_title"
            ).length > 0
              ? service.filter(
                  (item) => item.key === "home_actuality_title"
                )[0].value
              : ""
          }
        </h2>
        <div className="mx-auto d-flex flex-wrap justify-content-center justify-content-sm-evenly">
          {actualities.map((actu, i) => (
            <ActualiteListCard
              actualite={actu}
              key={i}
              transitionTime={Number(
                service.filter(
                  (item) => item.key === "global_photo_time_transition"
                ).length > 0
                  ? service.filter(
                      (item) => item.key === "global_photo_time_transition"
                    )[0].value
                  : 800
              )}
              standTime={Number(
                service.filter((item) => item.key === "global_photo_time_stand")
                  .length > 0
                  ? service.filter(
                      (item) => item.key === "global_photo_time_stand"
                    )[0].value
                  : 2200
              )}
              style={{ color: "black" }}
            />
          ))}
        </div>
        <div className="w-100 d-flex">
          {nextActualities.length > 0 && (
            <button className="mx-auto bouton-actu" onClick={handleMore}>
              Voir plus d'actualités
            </button>
          )}
        </div>
      </div>
    </>
  );
}
/**
 *
 */
