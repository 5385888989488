import { Outlet } from "react-router-dom";
import "./Admin.scss";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/esm/Navbar";
import { useState } from "react";
import { TokenContext } from "./TokenContext";
import { AdminLogin } from "./Login";
import { Requester } from "../../Requester/Requester";
import { LinkCustom } from "../App/linkCustom";

export function Admin() {
  const [token, setToken] = useState("");
  

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      <main className="d-flex">
        <Navbar className="flex-column sidebar bambou-border-large">
          <Nav className="flex-column">
            <LinkCustom key={1} to={`/admin`} title="Généralités" />
            {token && Requester.tables.map((table: string, i: number) => (
              <LinkCustom
                key={i + 2}
                to={`/admin/${table}`}
                title={table.charAt(0).toUpperCase() + table.slice(1)}
              />
            ))
            }
            {}
          </Nav>
        </Navbar>
        <div>
          {!token && <AdminLogin />}
          {token && <Outlet />}
        </div>
      </main>
    </TokenContext.Provider>
  );
}
