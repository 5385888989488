import "../formations.scss";
import { TProgramme } from "../../../Types/Programme.type";
import { PhotosView } from "../../App/components/Photos/PhotosView";
import { TPhoto } from "../../../Types/Photo.type";

interface TProgrammeViewInfo {
  programme: TProgramme;
  transitionTime: number;
  standTime: number;
}

export function ProgrammeViewInfo({
  programme,
  transitionTime,
  standTime,
}: TProgrammeViewInfo) {
  return (
    <div className="p-3 high-light-box-a rounded-5">
      <h2 className="text-center">{programme.title}</h2>
      <div className="d-md-flex ps-3">
        <PhotosView
          photos={programme.formations.reduce(
            (acc, item) => [...acc, ...item.photos],
            [] as TPhoto[]
          )}
          transitionTime={transitionTime}
          standTime={standTime}
          size={"20em"}
          maxSize={"75vw"}
          radius={"2em"}
        />
        <div className="p-3">
          {programme.content.split("\n").map((item, i) => (
            <p key={i}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
}
