import { TActuality } from "../Types/Actuality.type";
import { TModule } from "../Types/Module.type";
import { TProgramme } from "../Types/Programme.type";
import { TLieu } from "../Types/Lieu.type";
import { TProduction } from "../Types/Production.type";
import { TProduit } from "../Types/Produit.type";
import { RequesterApp } from "./RequesterApp";
import { RequesterCRUD } from "./RequesterCRUD"
import { RequesterCRUDPhoto } from "./RequesterCRUDPhoto";
import { RequesterVarieties } from "./RequesterVarieties";
import { RequesterNames } from "./RequesterSteps";
import { TContact } from "../Types/Contact.type";
import { RequesterRU } from "./RequesterRU";
import { TSiteServices } from "../Types/SiteService.type";

/**
 * Centralisation Requesters
 * 
 * @version v1
 */
export class Requester {

  static app = new RequesterApp() ;
  static siteServices = new RequesterRU<TSiteServices>("site-services") ;
  static contacts = new RequesterCRUD<TContact>("contacts") ;
  static actualities = new RequesterCRUDPhoto<TActuality>("actualities") ;
  static modules = new RequesterCRUDPhoto<TModule>("formations") ;
  static programmes = new RequesterCRUD<TProgramme>("bundles") ;
  static varieties = new RequesterVarieties()
  static produits = new RequesterCRUDPhoto<TProduit>("products") ;
  static productions = new RequesterCRUDPhoto<TProduction>("productions") ;
  static lieux = new RequesterCRUDPhoto<TLieu>("localities") ;
  static steps = new RequesterNames("formation-steps") ;
  static prerequests = new RequesterNames("formation-prerequests") ;
  static evaluations = new RequesterNames("formation-evaluations") ;
  static objectifs = new RequesterNames("formation-objectifs") ;
  static methods = new RequesterNames("formation-methods") ;
  static get tables()
  {
    return ['site-services','contacts','actualites', 'programmes', 'modules'/* , 'produits', 'productions', 'lieux' */]
  } 
}