import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Requester } from "../../../../Requester/Requester";
import { TModuleItemOrders } from "../../../../Types/ModuleItemOrders.type";

interface IOrderedItemUpdateProps {
  table : "prerequests" | "evaluations" | "objectifs" | "methods";
  title : string;
  itemName : string;
  values : TModuleItemOrders[] ;
  setValues : (val : TModuleItemOrders[]) => void ;
}
export function OrderedItemUpdate ({
  table,
  title,
  itemName,
  values,
  setValues,
} : IOrderedItemUpdateProps) {
  

  const [selectedName, setSelectedName] = useState<string>("");
  const [names, setNames] = useState<string[]>([]);
  
  useEffect(() => {
    const fillNames = async () => {
      setNames(await Requester[table].getAll());
    };
    fillNames();
  }, []);


  
  const addStep = () => {
    setValues([
      ...values,
      {
        id: -1,
        name: selectedName,
        order: values.length + 1,
      },
    ]);
    setSelectedName("");
  };

  const suppStep = (order: number) => {
    setValues( values
      .filter((step) => step.order !== order)
      .map((step) => {
        const newStep = { ...step };
        if (newStep.order > order) {
          newStep.order -= 1;
        }
        return newStep;
      }));
    setSelectedName("");
  };

  const switchStep = (orderA: number, orderB: number) => {
    const newValues = [...values];
    newValues[orderA - 1].order = orderB;
    newValues[orderB - 1].order = orderA;
    newValues.sort((a, b) => a.order - b.order);
    setValues(newValues);
  };

  return (
    <>
    <hr />
    <h5>{title}</h5>
    <Table striped>
      <thead>
        <tr>
          <th className="col-button"></th>
          <th className="col-button"></th>
          <th className="px-3">Nom</th>
          <th className="col-button"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {selectedName !== "" && (
              <button
                onClick={addStep}
                className="icon-rondelle border-0 bg-transparent"
              >
                <img
                  className="icon-table"
                  src="/bordures/icon-rondelle-add.svg"
                  alt="Ajouter une étape"
                />
              </button>
            )}
          </td>
          <td></td>
          <td className="px-3 d-flex">
            <select
              id="module-update-steps"
              className="p-1"
              style={{ width: "1.4em" }}
              value={""}
              onChange={(e) => setSelectedName(e.target.value)}
            >
              <option value={""} key={-1}>{" "}</option>
              {names
                .filter((name) => name.includes(selectedName))
                .map((name, i) => (
                  <option value={name} key={i}>
                    {" "}
                    {name}
                  </option>
                ))}
            </select>
            <input
              className="p-1"
              style={{ width: "calc(100% - 1.4em)" }}
              value={selectedName}
              onChange={(e) => setSelectedName(e.target.value)}
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td colSpan={4}>
            <hr />
          </td>
        </tr>
        {values.map((item, i) => (
          <tr key={i}>
            <td>
              {item.order !== 1 && (
                <button
                  onClick={() => switchStep(item.order, item.order - 1)}
                  className="icon-rondelle border-0 bg-transparent"
                >
                  <img
                    className="icon-table"
                    src="/bordures/icon-rondelle-up.svg"
                    alt="Supprimer le module"
                  />
                </button>
              )}
            </td>
            <td>
              {item.order !== values.length && (
                <button
                  onClick={() => switchStep(item.order, item.order + 1)}
                  className="icon-rondelle border-0 bg-transparent"
                >
                  <img
                    className="icon-table"
                    src="/bordures/icon-rondelle-down.svg"
                    alt="Supprimer le module"
                  />
                </button>
              )}
            </td>
            <td className="px-3">{item.name}</td>
            <td>
              <button
                onClick={() => suppStep(item.order)}
                className="icon-rondelle border-0 bg-transparent"
              >
                <img
                  className="icon-table"
                  src="/bordures/icon-rondelle-delete.svg"
                  alt="Supprimer le module"
                />
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={2}></td>
          <td >
            <hr />
          </td>
          <td ></td>
        </tr>
        <tr>
          <td colSpan={2}>
            <strong>Totaux</strong>
          </td>
          <td className="px-3">
            {values.length} {itemName}
            {itemName[itemName.length -1] !== "s" && values.length > 1 ? "s" : ""}
          </td>
          <td ></td>
        </tr>
      </tbody>
    </Table>
      
    </>
  )
}