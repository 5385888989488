
import { RequestMethods } from "../Types/RequestMethod.enum";
import { TResponse } from "../Types/Response.type";
import { RequesterCRUD } from "./RequesterCRUD";

export class RequesterCRUDPhoto<TData> extends RequesterCRUD<TData> {


  async create(body : TData, token : string , files? : FileList ) : Promise<TResponse<TData,string | string[]>>
  {
    const response = await RequesterCRUD.base<TData,string | string[]>(
      this.route,
      RequestMethods.POST ,
      [],
      body,
      token,
      files
      )
    return response
  } ;

  async getAll(querry? :{page : number, itemsByPage : number }): Promise<TResponse<TData[],string>>
  {
    const response = await RequesterCRUD.base<TData[],string>(
      this.route
      )
    return response
  } ;

  async getOne(id :number) : Promise<TResponse<TData,string>>
  {
    const response = await RequesterCRUD.base<TData,string>(
      this.route,
      RequestMethods.GET ,
      [id]
      )
    return response
  } ;

  async update(id :number, body : TData & {photosRemoved? : number[]}, token : string , files? : FileList) : Promise<TResponse<TData,string | string[]>>
  {
    const response = await RequesterCRUD.base<TData,string | string[]>(
      this.route,
      RequestMethods.PATCH ,
      [id],
      body,
      token,
      files
      )
    return response
  } ;

  async delete(id :number , token : string  ) : Promise<TResponse<TData,string>>
  {
    const response = await RequesterCRUD.base<TData,string>(
      this.route,
      RequestMethods.DELETE ,
      [id],
      undefined,
      token
      )
    return response
  } ;

}