import { Link } from "react-router-dom";
import { TModule } from "../../../Types/Module.type";
import { TProgramme } from "../../../Types/Programme.type";
import { TPhoto } from "../../../Types/Photo.type";
import { minToHourString } from "../../../Utilities/MinToHourString";
import { PhotosView } from "../../App/components/Photos/PhotosView";

interface IProgrammeCardProps {
  programme: TProgramme;
  transitionTime: number;
  standTime: number;
  style?: React.CSSProperties;
  className? : string
}
export function ProgrammeCard({
  programme,
  transitionTime,
  standTime,
  style,
  className
}: IProgrammeCardProps) {


  return (
    <Link
      to={`/programmes/${programme.id}`}
      className={`d-block text-center item-card programme ${className}`}
      style={style}
    >
      <div>
        <div>
          <h5 className="m-0 p-2">{programme.title}</h5>
          <div className="d-flex d-md-block">
            <PhotosView
              photos={programme.formations.reduce(
                (acc: TPhoto[], programme: TModule) => [
                  ...acc,
                  ...programme.photos,
                ],
                []
              )}
              transitionTime={transitionTime}
              standTime={standTime}
              size={"100%"}
              maxSize={"100%"}
              radius={"0"}
            />
            <div className="flex-fill d-md-flex align-content-evenly item-card-data">
              <div className="w-100">
                <p>{programme.formations.length} modules</p>
                <p>{minToHourString(programme.duration)}</p>
              </div>
              <div className="w-100">
                <p>{programme.price}€</p>
                <p className="text-decoration-line-through">
                  {programme.fullprice}€
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
