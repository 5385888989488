import { GroupSize } from "../Types/GroupSize";
import { TModule } from "../Types/Module.type";

export const DEFAULT_MODULE : TModule = {
  id: -1,
  title: "Titre",
  content: "Contenu",
  duration: 0,
  price: 0,
  group_size: GroupSize.S,
  photos: [],
  formation_steps : [],
  contacts : [],
  formation_prerequests :[],
  formation_evaluations :[],
  formation_objectifs :[],
  formation_methods :[],
}