import { TProgramme } from "../../../Types/Programme.type";
import { minToHourString } from "../../../Utilities/MinToHourString";

export function ProgrammeViewDonnees(props: { programme: TProgramme }) {
  const { programme } = props;
  return (
    <div className="d-md-flex justify-content-around p-3 high-light-box-g rounded-5">
      <div>
        <h3>Durée</h3>
        <p>
          <strong>{minToHourString(programme.duration)}</strong>
        </p>
      </div>
      <div>
        <h3>Prix</h3>
        <p>
          <strong>{programme.price}€</strong>
        </p>
      </div>
      <div>
        <h5>Prix total des modules</h5>
        <p style={{textDecoration:"line-through"}}>{programme.fullprice}€</p>
      </div>
    </div>
  );
}
