import { useState, useEffect, useContext } from "react";
import { Link, Form, useLoaderData, useNavigate } from "react-router-dom";
import { DEFAULT_FORMULE } from "../../../Constants/programe.default";
import { Requester } from "../../../Requester/Requester";
import { TModule } from "../../../Types/Module.type";
import { TProgramme } from "../../../Types/Programme.type";
import { minToHourString } from "../../../Utilities/MinToHourString";
import { TokenContext } from "../TokenContext";

export function AdminProgrammeUpdate() {
  const id = useLoaderData() as number;

  const { token } = useContext(TokenContext);
  const [programme, setProgramme] = useState<TProgramme>(DEFAULT_FORMULE);
  const [modules, setModules] = useState<TModule[]>([]);
  const [module, setModule] = useState<TModule>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillProgramme = async () => {
      const response = await Requester.programmes.getOne(id);
      if (response.data) {
        setProgramme(response.data);
      } else {
        setProgramme(DEFAULT_FORMULE);
        navigate(`/admin/programmes`);
      }
    };

    if (id === -1) {
      setProgramme(DEFAULT_FORMULE);
    } else {
      fillProgramme();
    }
  }, [id, navigate]);

  useEffect(() => {
    const fillModules = async () => {
      const response = await Requester.modules.getAll();
      if (response.data) {
        setModules(response.data);
        setModule(response.data[0]);
      } else {
        setModules([]);
        setModule(undefined);
      }
    };

    fillModules();
  }, [id, navigate]);

  const handleModif = (key: "title" | "content" | "price", value: string) => {
    const newValue = { ...programme };
    if (key === "price") {
      newValue[key] = Number(value);
    } else {
      newValue[key] = value;
    }
    setProgramme(newValue);
  };

  const changeModule = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newModule = modules.filter(
      (item) => item.id === Number(event.target.value)
    )[0];
    setModule(newModule);
  };

  const addModule = () => {
    const newProgramme = { ...programme };
    newProgramme.formations = [...programme.formations, module!];
    setProgramme(newProgramme);
  };

  const suppModule = (index: number) => {
    const newProgramme = { ...programme };
    newProgramme.formations = programme.formations.filter(
      (_, i) => i !== index
    );
    setProgramme(newProgramme);
    setModule(
      modules.filter((item) => {
        let result = true;
        newProgramme.formations.forEach(
          (elm) => (result = result && elm.id !== item.id)
        );
        return result;
      })[0]
    );
  };

  const handleRequest = async () => {
    if (id !== -1) {
      const response = await Requester.programmes.update(id, programme, token);
      await Promise.all([response]);
      navigate(`/admin/programmes/view/${response.data.id}`);
    } else {
      const response = await Requester.programmes.create(programme, token);
      await Promise.all([response]);
      navigate(`/admin/programmes/view/${response.data.id}`);
    }
  };

  return (
    <>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h4>
          {id === -1 ? "Nouvelle Programme" : "Modification d'une Programme"}
        </h4>
        <div>
          <Link
            to={`/admin/programmes/view/${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleRequest();
            }}
          >
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-save.svg"
              alt="Enregistrer le programme"
            />
          </Link>
          <Link to={`/admin/programmes/view/${id}`}>
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-cancel.svg"
              alt="Annuler"
            />
          </Link>
        </div>
      </div>
      <Form className="bambou-border-large main-box">
        <h5>Généralités</h5>
        <div className="admin-label-input">
          <label htmlFor="programme-update-title">Titre</label>
          <input
            type="text"
            id="programme-update-title"
            value={programme.title}
            onChange={(e) => handleModif("title", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="programme-update-content">Contenu</label>
          <textarea
            id="programme-update-content"
            value={programme.content}
            onChange={(e) => handleModif("content", e.target.value)}
          />
        </div>
        <hr />
        <h5>Modules</h5>
        <table>
          <tbody>
            <tr>
              <td>
                <button
                  onClick={addModule}
                  className="icon-rondelle border-0 bg-transparent"
                >
                  <img
                    style={{ width: "2em"}}
                    src="/bordures/icon-rondelle-add.svg"
                    alt="Ajouter le module"
                  />
                </button>
              </td>
              <td className="px-3">
                <select
                  id="programme-update-formations"
                  className="p-1"
                  value={module?.id}
                  onChange={changeModule}
                >
                  {modules.map((item, i) => (
                    <option value={item.id} key={i}>
                      {" "}
                      {item.title}
                    </option>
                  ))}
                </select>
              </td>
              <td className="px-3">
                {module !== undefined
                  ? `${minToHourString(module.duration)}`
                  : ""}
              </td>
              <td className="px-3 text-center">
                {module !== undefined ? `${module.price}€` : ""}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <hr />
              </td>
            </tr>
            {programme?.formations.map((item, i) => (
              <tr key={i}>
                <td>
                  <button
                    onClick={() => suppModule(i)}
                    className="icon-rondelle border-0 bg-transparent"
                  >
                    <img
                      style={{ width: "2em"}}
                      src="/bordures/icon-rondelle-delete.svg"
                      alt="Supprimer le module"
                    />
                  </button>
                </td>
                <td className="px-3">{item.title}</td>
                <td className="px-3">{minToHourString(item.duration)}</td>
                <td className="px-3 text-center">{item.price}€</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td colSpan={3}>
                <hr />
              </td>
            </tr>
            <tr>
              <td className="px-3">
                <strong>Totaux</strong>
              </td>
              <td className="px-3">
                {" "}
                {programme?.formations.length} Module
                {programme && programme.formations.length > 1 ? "s" : ""}
              </td>
              <td className="px-3">
                {module !== undefined
                  ? `${minToHourString(
                      programme?.formations.reduce(
                        (acc, item) => acc + item.duration,
                        0
                      )
                    )}`
                  : ""}
              </td>
              <td className="px-3">
                <del>
                  {module !== undefined
                    ? `${programme?.formations.reduce(
                        (acc, item) => acc + item.price,
                        0
                      )}€`
                    : ""}
                </del>
              </td>
            </tr>
            <tr>
              <td className="px-3">
                <label htmlFor="programme-update-price">Prix</label>
              </td>
              <td colSpan={2}>
                <hr />
              </td>
              <td>
                <input
                  type="number"
                  id="programme-update-quantity"
                  className="p-1"
                  value={programme.price}
                  onChange={(e) => handleModif("price", e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Form>
    </>
  );
}
