import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Requester } from "../../../Requester/Requester";
import { TContact } from "../../../Types/Contact.type";
import { ContactTableItem } from "./Components/ContactTableItem";
export function AdminContacts() {
  const [contacts, setContacts] = useState<TContact[]>([]);
  const [contactsFilter, setContactsFilter] = useState<TContact[]>([]);
  const [searchName, setSearchName] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const fillContacts = async () => {
      const response = await Requester.contacts.getAll();
      if (response.data) {
        setContacts(response.data);
      }
    };
    fillContacts();
  }, [location]);

  useEffect(() => {
    setContactsFilter(
      contacts
        .filter((item) =>
          searchName
            ? `${item.firstName} ${item.lastName}`
                .toLowerCase()
                .search(searchName.toLowerCase()) > -1
            : true
        )
        .sort((a, b) => {
          let fullA = `${a.firstName} ${a.lastName}`;
          let fullB = `${b.firstName} ${b.lastName}`
          if (fullA < fullB) {
            return -1;
          } else if (fullA > fullB) {
            return 1;
          }
          return 0;
        })
    );
  }, [contacts, searchName]);

  const handleSearchName = (value: string) => {
    setSearchName(value);
  };
  return (
    <nav>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h3>Gestion des Contacts</h3>
        <Link to={`/admin/contacts/new`} className="icon-rondelle">
          <img
            src="/bordures/icon-rondelle-add.svg"
            alt="Ajout d'un Contact"
          />
        </Link>
      </div>

      <div className="bambou-border-large main-box">
        <Table striped hover>
          <thead>
            <tr>
              <th>
                <div><label htmlFor="search-name">Nom :</label>{" "}
                <input
                  id="search-name"
                  value={searchName}
                  onChange={(e) => handleSearchName(e.target.value)}
                ></input></div>
              </th>
              <th>Mail</th>
              <th>Téléphone</th>
            </tr>
          </thead>
          <tbody>
            {contactsFilter.map((item, i) => (
              <ContactTableItem key={i} contact={item} />
            ))}
          </tbody>
        </Table>
      </div>
    </nav>
  );
}
