import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Requester } from "../../../../Requester/Requester";
import { TModuleSteps } from "../../../../Types/ModuleStep.type";
import { minToHourString } from "../../../../Utilities/MinToHourString";

interface IStepUpdateProps {
  values: TModuleSteps[];
  setValues: (val: TModuleSteps[]) => void;
}
export function StepUpdate({ values, setValues }: IStepUpdateProps) {
  const [stepName, setStepName] = useState<string>("");
  const [stepDurantion, setStepDurantion] = useState<number>(0);
  const [stepNames, setStepNames] = useState<string[]>([]);

  useEffect(() => {
    const fillNames = async () => {
      setStepNames(await Requester.steps.getAll());
    };
    fillNames();
  }, []);

  const addStep = () => {
    setValues([
      ...values,
      {
        id: -1,
        name: stepName,
        duration: stepDurantion,
        order: values.length + 1,
      },
    ]);
    setStepName("");
    setStepDurantion(0);
  };

  const suppStep = (order: number) => {
    setValues(
      values
        .filter((step) => step.order !== order)
        .map((step) => {
          const newStep = { ...step };
          if (newStep.order > order) {
            newStep.order -= 1;
          }
          return newStep;
        })
    );
    setStepName("");
    setStepDurantion(0);
  };

  const switchStep = (orderA: number, orderB: number) => {
    const newValues = [...values];
    newValues[orderA - 1].order = orderB;
    newValues[orderB - 1].order = orderA;
    newValues.sort((a, b) => a.order - b.order);
    setValues(newValues);
  };

  return (
    <>
      <hr />
      <h5>Déroulé du module</h5>
      <Table striped>
        <thead>
          <tr>
            <th className="col-button"></th>
            <th className="col-button"></th>
            <th className="px-3">Nom</th>
            <th className="px-1">Durée</th>
            <th className="col-button"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {stepName !== "" && stepDurantion !== 0 && (
                <button
                  onClick={addStep}
                  className="icon-rondelle border-0 bg-transparent"
                >
                  <img
                    className="icon-table"
                    src="/bordures/icon-rondelle-add.svg"
                    alt="Ajouter une étape"
                  />
                </button>
              )}
            </td>
            <td></td>
            <td className="px-3 d-flex">
              <select
                id="module-update-steps"
                className="p-1"
                style={{ width: "1.4em" }}
                value={""}
                onChange={(e) => setStepName(e.target.value)}
              >
                <option value={""} key={-1}>
                  {" "}
                </option>
                {stepNames
                  .filter((name) => name.includes(stepName))
                  .map((name, i) => (
                    <option value={name} key={i}>
                      {" "}
                      {name}
                    </option>
                  ))}
              </select>
              <input
                className="p-1"
                style={{ width: "calc(100% - 1.4em)" }}
                value={stepName}
                onChange={(e) => setStepName(e.target.value)}
              />
            </td>
            <td>
              <input
                className="px-1"
                type="number"
                min={0}
                value={stepDurantion}
                onChange={(e) => setStepDurantion(Number(e.target.value) || 0)}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={5}>
              <hr />
            </td>
          </tr>
          {values.map((item, i) => (
            <tr key={i}>
              <td>
                {item.order !== 1 && (
                  <button
                    onClick={() => switchStep(item.order, item.order - 1)}
                    className="icon-rondelle border-0 bg-transparent"
                  >
                    <img
                      className="icon-table"
                      src="/bordures/icon-rondelle-up.svg"
                      alt="Supprimer le module"
                    />
                  </button>
                )}
              </td>
              <td>
                {item.order !== values.length && (
                  <button
                    onClick={() => switchStep(item.order, item.order + 1)}
                    className="icon-rondelle border-0 bg-transparent"
                  >
                    <img
                      className="icon-table"
                      src="/bordures/icon-rondelle-down.svg"
                      alt="Supprimer le module"
                    />
                  </button>
                )}
              </td>
              <td className="px-3">{item.name}</td>
              <td className="px-3">{minToHourString(item.duration)}</td>
              <td>
                <button
                  onClick={() => suppStep(item.order)}
                  className="icon-rondelle border-0 bg-transparent"
                >
                  <img
                    className="icon-table"
                    src="/bordures/icon-rondelle-delete.svg"
                    alt="Supprimer le module"
                  />
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={2}></td>
            <td colSpan={2}>
              <hr />
            </td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={2}>
              <strong>Totaux</strong>
            </td>
            <td className="px-3">
              {values.length} étape
              {values.length > 1 ? "s" : ""}
            </td>
            <td className="px-3">
              {module !== undefined
                ? `${minToHourString(
                    values.reduce((acc, item) => acc + item.duration, 0)
                  )}`
                : ""}
            </td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
