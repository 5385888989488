
import { RequestMethods } from "../Types/RequestMethod.enum";
import { TResponse } from "../Types/Response.type";
import { RequesterBase } from "./RequesterBase";


export class RequesterRU<TData> extends RequesterBase {

  route : string
  constructor(route : string){
    super();
    this.route = route
  }


  async getAll() : Promise<TResponse<TData[],string>>
  {
    const response = await RequesterRU.base<TData[],string>(
      this.route,
      RequestMethods.GET ,
      [],
      undefined
      )
    return response
  } ;

  async getOne(key :string) : Promise<TResponse<TData,string>>
  {
    const response = await RequesterRU.base<TData,string>(
      this.route,
      RequestMethods.GET ,
      [key],
      undefined
      )
    return response
  } ;

  async update(key :string, body : Partial<TData> , token : string ) : Promise<TResponse<TData,string | string[]>>
  {
    const response = await RequesterRU.base<TData,string | string[]>(
      this.route,
      RequestMethods.PATCH ,
      [key],
      body,
      token
      )
    return response
  } ;

}