import { useState, useEffect, useContext } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { TContact } from "../../../Types/Contact.type";
import { Requester } from "../../../Requester/Requester";

export function AdminContactDelete() {
  const id = useLoaderData() as number;

  const {token} = useContext(TokenContext)
  const [contact, setContact] = useState<TContact>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillContact = async () => {
      const response = await Requester.contacts.getOne(id);
      if (response.data) {
        setContact(response.data);
      } else {
        setContact(undefined);
        navigate(`/admin/contacts`)
      }
    };
    
    fillContact();
  }, [id,navigate]);
  
  const handleRequest = async () => {
      const response = await Requester.contacts.delete(id,token) ;
      await Promise.all([response]) ;
      navigate(`/admin/contacts`)
  }

  return (
    <>
    <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
      <h4>Suppression d'un Contact</h4>
      </div>
      <div className="bambou-border-large main-box">
      <p>Etes-vous sur de vouloir supprimer {contact?.firstName} {contact?.lastName}</p>
        <Link
          className="rondelle-border"
          to={`/admin/contacts/view/${id}`}
          onClick={(e) => {
            e.preventDefault();
            handleRequest();
          }}
        >
          <div>Oui</div>
        </Link>
        <Link className="rondelle-border" to={`/admin/contacts/view/${id}`}>
          <div>Non</div>
        </Link>
      </div>
    </>
  );
}
