
import { Table } from "react-bootstrap";
import { TModuleItemOrders } from "../../../../Types/ModuleItemOrders.type";

interface IOrderedItemViewProps {
  title: string;
  values: TModuleItemOrders[];
}
export function OrderedItemView({ title, values }: IOrderedItemViewProps) {
  return (
    <>
      {values.length > 0 && (
        <>
          <hr />
          <h5>{title}</h5>
          <Table striped>
            <tbody>
              {values.map((item, i) => (
                <tr key={i}>
                  <td className="px-3">{item.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}
