import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import { ProgrammeTableItem } from "./Components/ProgrammeTableItem";
import { Requester } from "../../../Requester/Requester";
import { TProgramme } from "../../../Types/Programme.type";
export function AdminProgrammes() {
  const [programmes, setProgrammes] = useState<TProgramme[]>([]);
  const [programmesFilter, setProgrammesFilter] = useState<TProgramme[]>([]);
  const [searchName, setSearchName] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const fillModules = async () => {
      const response = await Requester.programmes.getAll();
      if (response.data) {
        setProgrammes(response.data);
      }
    };
    fillModules();
  }, [location]);

  useEffect(() => {
    setProgrammesFilter(
      programmes
        .filter((item) =>
          searchName
            ? item.title
                .toLowerCase()
                .search(searchName.toLowerCase()) > -1
            : true
        )
        .sort((a, b) => {
          let fullA = a.title;
          let fullB = b.title;
          if (fullA < fullB) {
            return -1;
          } else if (fullA > fullB) {
            return 1;
          }
          return 0;
        })
    );
  }, [programmes, searchName]);

  const handleSearchName = (value: string) => {
    setSearchName(value);
  };
  
  return (
    <nav>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h3>Gestion des Programmes</h3>
        <Link to={`/admin/programmes/new`} className="icon-rondelle">
          <img
            src="/bordures/icon-rondelle-add.svg"
            alt="Ajout d'un programme"
          />
        </Link>
      </div>

      <div className="bambou-border-large main-box">
        <Table striped hover>
          <thead>
            <tr>
              <th>
                <div><label htmlFor="search-name">Titre :</label>{" "}
                <input
                  id="search-name"
                  value={searchName}
                  onChange={(e) => handleSearchName(e.target.value)}
                ></input></div>
              </th>
              <th className={"col-small"}><div>Lot</div></th>
              <th className={"col-small"}><div>Durée</div></th>
              <th className={"col-small"}><div>Prix</div></th>
              <th className={"col-small barre"}><div>Prix</div></th>
              <th className={"col-small"}><div>Actions</div></th>
            </tr>
          </thead>
          <tbody>
            {programmesFilter.map((item, i) => (
              <ProgrammeTableItem key={i} programme={item} />
            ))}
          </tbody>
        </Table>
      </div>
    </nav>
  );
}
