import { useState, useEffect, useContext } from "react";
import { Link, Form, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { DEFAULT_PRODUCTION } from "../../../Constants/production.default";
import { Requester } from "../../../Requester/Requester";
import { TLieu } from "../../../Types/Lieu.type";
import { TPhoto } from "../../../Types/Photo.type";
import { TProductionBody } from "../../../Types/Production.type";
import { TProduit } from "../../../Types/Produit.type";
import { AdminPhotosUpdate } from "../Photos/AdminPhotosUpdate";

export function AdminProductionUpdate() {
  const id = useLoaderData() as number;

  const {token} = useContext(TokenContext)
  const [produits, setProduits] = useState<TProduit[]>([]);
  const [lieux, setLieux] = useState<TLieu[]>([]);
  const [production, setProduction] = useState<TProductionBody>({
    ...DEFAULT_PRODUCTION,
    localityId: -1,
    productId: -1,
  });
  const [gotRecolte, setGotRecolte] = useState(false);
  const [filesPhotos, setFilesPhotos] = useState<FileList | null>(null);

  const navigate = useNavigate();

  useEffect(() => {

    const fillProduits = async () => {
      const response = await Requester.produits.getAll();
      if (response.data) {
        setProduits(response.data);
      }
    };

    fillProduits();
  },[])

  useEffect(() => {

    const fillLieux = async () => {
      const response = await Requester.lieux.getAll();
      if (response.data) {
        setLieux(response.data);
      }
    };
    fillLieux();

  },[])

  useEffect(() => {
    const fillProduction = async () => {
      const response = await Requester.productions.getOne(id);
      if (response.data) {

        setProduction({
          ...response.data,
          localityId: response.data.locality.id,
          productId: response.data.product.id,
        });
        setGotRecolte(response.data.end !== null);
      } else {
        setProduction({
          ...DEFAULT_PRODUCTION,
          localityId: production.localityId,
          productId: production.productId,
        });
        navigate(`/admin/productions`);
      }
    };


    if (id === -1) {
      if (lieux.length && produits.length){
        
        setProduction({
          ...DEFAULT_PRODUCTION,
          localityId: lieux[0].id,
          productId: produits[0].id,
        });
      }
    } else {
      fillProduction();
    }
  }, [id, navigate, lieux, produits]);

  const handleModif = (
    key:
      | "name"
      | "description"
      | "start"
      | "startquantity"
      | "end"
      | "endquantity"
      | "localityId"
      | "productId",
    value: string
  ) => {
    const newValue = { ...production };
    if (
      key === "startquantity" ||
      key === "endquantity" ||
      key === "localityId" ||
      key === "productId"
    ) {
      newValue[key] = Number(value);
    } else {
      newValue[key] = value;
    }
    setProduction(newValue);
  };

  const handleRequest = async () => {
    if (id !== -1) {
      const response = await Requester.productions.update(
        id,
        production,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/productions/view/${id}`);
    } else {
      const response = await Requester.productions.create(
        production,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/productions/view/${response.data.id}`);
    }
  };

  const handlePhotos = (value: TPhoto[]) => {
    const newProduction = { ...production };
    newProduction.photos = value;
    setProduction(newProduction);
  };

  return (
    <>
    <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
      <h4>
        {id === -1 ? "Nouvelle Production" : "Modification d'une Production"}
      </h4>
        <div>
          <Link
            to={`/admin/productions/view/${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleRequest();
            }}
          >
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-save.svg"
              alt="Enregistrer la production"
            />
          </Link>
          <Link to={`/admin/productions/view/${id}`}>
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-cancel.svg"
              alt="Annuler"
            />
          </Link>
        </div>
      </div>
      <Form className="bambou-border-large main-box">
        <div className="admin-label-input">
          <label htmlFor="productions-update-name">Nom</label>
          <input
            type="text"
            id="productions-update-name"
            value={production.name}
            onChange={(e) => handleModif("name", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="actualite-update-locality">Lieu :</label>
          <select
            id="actualite-update-locality"
            value={production.localityId}
            onChange={(e) => handleModif("localityId", e.target.value)}
          >
            {lieux.map((item, i) => (
              <option key={i} value={item.id}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
        <div className="admin-label-input">
          <label htmlFor="actualite-update-product">Produit :</label>
          <select
            id="actualite-update-product"
            value={production.productId}
            onChange={(e) => handleModif("productId", e.target.value)}
          >
            {produits.map((item, i) => (
              <option key={i} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="admin-label-input">
          <label htmlFor="productions-update-description">Description</label>
          <textarea
            id="productions-update-description"
            value={production.description}
            onChange={(e) => handleModif("description", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="actualite-update-start">Date de Semie</label>
          <input
            type="date"
            id="actualite-update-start"
            value={production.start}
            onChange={(e) => handleModif("start", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="actualite-update-startquantity">
            Nombre de Semies
          </label>
          <input
            type="number"
            id="actualite-update-startquantity"
            value={production.startquantity}
            onChange={(e) => handleModif("startquantity", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="actualite-update-gotRecolte">
            Ajouter la Récolte
          </label>
          <input
            type="checkbox"
            id="actualite-update-gotRecolte"
            value={String(gotRecolte)}
            readOnly
            onChange={() => {
              const newProduction = { ...production };
              if (gotRecolte) {
                newProduction.end = null;
                newProduction.endquantity = null;
              } else {
                newProduction.end = new Date().toISOString();
                newProduction.endquantity = 0;
              }
              setProduction(newProduction);
              setGotRecolte(!gotRecolte);
            }}
          />
        </div>
        {gotRecolte && (
          <div>
            <div>
              <label htmlFor="actualite-update-end">Date de Récolte</label>
              <input
                type="date"
                id="actualite-update-end"
                value={production.end!}
                onChange={(e) => handleModif("end", e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="actualite-update-endquantity">Récolte</label>
              <input
                type="number"
                id="actualite-update-endquantity"
                value={production.endquantity!}
                onChange={(e) => handleModif("endquantity", e.target.value)}
              />
            </div>
          </div>
        )}
        <AdminPhotosUpdate
          photos={production.photos}
          setPhotos={handlePhotos}
          files={filesPhotos}
          setFiles={setFilesPhotos}
        />
      </Form>
    </>
  );
}
