import { TPhoto } from "../../../Types/Photo.type";


export function AdminPhotosView(props: { photos: TPhoto[] }) {
  const { photos } = props;
  return (
    <div>
      <hr />
      <h5>Photos</h5>
      {photos.map((item, i) => (
        <div className="d-flex p-2" key={i}>
          <img
            className="medium-image"
            crossOrigin="anonymous"
            src={`${process.env.REACT_APP_API_URL}/photos/${item.path}`}
            alt={item.alt}
            key={i}
          />
          <p className="p-2">{item.alt}</p>
        </div>
      ))}
    </div>
  );
}
