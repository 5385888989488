import { TModule } from "../../../Types/Module.type";

export function ModuleViewEtapes(props: { module: TModule }) {
  const { module } = props;
  return (
    <div className="p-3 high-light-box-e rounded-5 flex-md-fill">
      <h3 className="text-center">Déroulé du module</h3>
      {module.formation_steps.map((item, i) => (
        <div key={i}>
          <p className="mt-3 mb-0 text-center">
            <strong>
              {item.name[0].toUpperCase()}
              {item.name.substring(1)}
            </strong>
          </p>
          <p className="mb-3 mt-0 text-center">
            {item.duration} minute{item.duration > 1 && "s"}
          </p>
        </div>
      ))}
    </div>
  );
}
