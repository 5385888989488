import { useState, useEffect, useContext } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { TokenContext } from "../TokenContext";
import { TLieu } from "../../../Types/Lieu.type";
import { Requester } from "../../../Requester/Requester";

export function AdminLieuDelete() {
  const id = useLoaderData() as number;

  const {token} = useContext(TokenContext)
  const [lieu, setLieu] = useState<TLieu>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillLieu = async () => {
      const response = await Requester.lieux.getOne(id);
      if (response.data) {
        setLieu(response.data);
      } else {
        setLieu(undefined);
        navigate(`/admin/lieux`);
      }
    };

    fillLieu();
  }, [id, navigate]);

  const handleRequest = async () => {
    const response = await Requester.lieux.delete(id,token);
    await Promise.all([response]);
    navigate(`/admin/lieux`);
  };

  return (
    <>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h4>Suppression d'un Lieu</h4>
      </div>
      <div className="bambou-border-large main-box">
        <p>Etes-vous sur de vouloir supprimer {lieu?.title}</p>
        <Link
          className="rondelle-border"
          to={`/admin/lieux/view/${id}`}
          onClick={(e) => {
            e.preventDefault();
            handleRequest();
          }}
        >
          <div>Oui</div>
        </Link>
        <Link className="rondelle-border" to={`/admin/lieux/view/${id}`}>
          <div>Non</div>
        </Link>
      </div>
    </>
  );
}
