import { Link } from "react-router-dom";
import { TActuality } from "../../../../Types/Actuality.type";


export function ActualiteTableItem(props: { actualite: TActuality }) {
  const { actualite } = props;

  return (
    <tr className={"compact"}>
      <td>
        <Link to={`/admin/actualites/view/${actualite.id}`}>
          {actualite.photos.length > 0 && (
            <img
              className={"compact"}
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_API_URL}/photos/${actualite.photos[0].path}`}
              alt={actualite.photos[0].alt}
            />
          )}
        </Link>
      </td>
      <td>
        <Link to={`/admin/actualites/view/${actualite.id}`}>
          {new Date(actualite.date).toLocaleDateString()}
        </Link>
      </td>
      <td>
        <Link to={`/admin/actualites/view/${actualite.id}`}>
          {actualite.title}
        </Link>
      </td>
      <td>
        <div>
          <Link
            to={`/admin/actualites/update/${actualite.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-update.svg"
              alt="Modifier une actualité"
            />
          </Link>
          <Link
            to={`/admin/actualites/delete/${actualite.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-delete.svg"
              alt="Supprimer une actualité"
            />
          </Link>
        </div>
      </td>
    </tr>
  );
}
