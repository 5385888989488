
import { Table } from "react-bootstrap";
import { TModuleSteps } from "../../../../Types/ModuleStep.type";
import { minToHourString } from "../../../../Utilities/MinToHourString";

interface IStepViewProps {
  values: TModuleSteps[];
}
export function StepView({ values }: IStepViewProps) {
  return (
    <>
      <hr />
      <h5>Déroulé du module</h5>
      <Table striped>
        <tbody>
          {values.map((step, i) => (
            <tr key={i}>
              <td className="col-small"></td>
              <td className="px-3">{step.name}</td>
              <td className="px-3 col-medium">
                {minToHourString(step.duration)}
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>
          <tr>
            <td className="px-3">
              <strong>Total</strong>
            </td>
            <td className="px-3">
              {values.length} étape
              {values.length > 1 ? "s" : ""}
            </td>
            <td className="px-3">
              {`${minToHourString(
                values.reduce((acc, item) => acc + item.duration, 0)
              )}`}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
