import { Link } from "react-router-dom";
import { TModule } from "../../../../Types/Module.type";
import { minToHourString } from "../../../../Utilities/MinToHourString";

export function ModuleTableItem(props: { module: TModule }) {
  const { module } = props;

  return (
    <tr className={"compact"}>
      <td>
        <Link to={`/admin/modules/view/${module.id}`}>
          {module.photos.length > 0 && (
            <img
              className={"compact"}
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_API_URL}/photos/${module.photos[0].path}`}
              alt={module.photos[0].alt}
            />
          )}
        </Link>
      </td>
      <td>
        <Link to={`/admin/modules/view/${module.id}`}>
          {module.title}
        </Link>
      </td>
      <td>
        <Link to={`/admin/modules/view/${module.id}`}>
          {`${minToHourString(module.duration)}`}
        </Link>
      </td>
      <td>
        <Link to={`/admin/modules/view/${module.id}`}>
          {module.price}€
        </Link>
      </td>
      <td>
        <div>
          <Link
            to={`/admin/modules/update/${module.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-update.svg"
              alt="Modifier un module"
            />
          </Link>
          <Link
            to={`/admin/modules/delete/${module.id}`}
            className="icon-rondelle"
          >
            <img
              src="/bordures/icon-rondelle-delete.svg"
              alt="Supprimer un module"
            />
          </Link>
        </div>
      </td>
    </tr>
  );
}
