import { TProduit } from "../Types/Produit.type";

export const DEFAULT_PRODUIT : TProduit = {
  id: -1,
  name: "Nom",
  varietyName: "Variété",
  description: "Description",
  price: 0,
  quantity: 0,
  photos: []
}