import { Form, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { TokenContext } from "./TokenContext";
import { Requester } from "../../Requester/Requester";

export function AdminBoard() {
  const { token } = useContext(TokenContext);
  const navigate = useNavigate()

  const [inReset, setInReset] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);

  const handleReset = async () => {
    await Requester.app.reset(token,files ? files : undefined)
    navigate(`/admin/actualites`)
  }

  return (
    <>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h4>AdminBoard</h4>
      </div>
      <div className="bambou-border-large main-box">
        <h5 className="pt-3">Récupération du contenu de la base de données</h5>
        <button
          className="p-1 rounded bg-primary"
          onClick={async () => await Requester.app.extract(token)}
        >
          Extraction
        </button>
        <h5 className="pt-3">Reinitialisation de la base de données</h5>
        <Form>
          {!inReset && (
            <div>
              <div className="d-flex flex-column m-2">
                <label>Choix de l'archive</label>
                <input type="file" accept=".zip" onChange={(e)=> setFiles(e.target.files)} />
              </div>
              <button className="p-1 rounded bg-danger d-block m-2" onClick={()=>setInReset(true)}>
                Reset
              </button>
            </div>
          )}
          {inReset && (
            <div>
              <h5 className="m-2 w-100 text-center">
                Confirmer la réinitialisation
              </h5>
              <div className="d-flex justify-content-between">
                <button
                  style={{ width: "48%" }}
                  className="p-1 rounded bg-primary m-2"
                  onClick={()=>setInReset(false)}
                >
                  Non
                </button>
                <button
                  style={{ width: "48%" }}
                  className="p-1 rounded bg-danger m-2"
                  onClick={handleReset}
                >
                  Oui
                </button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </>
  );
}
