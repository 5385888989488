
import { TProgramme } from "../Types/Programme.type";

export const DEFAULT_FORMULE : TProgramme = {
  id: -1,
  title: "Titre",
  content: "Contenu",
  duration: 0,
  price: 0,
  fullprice: 0,
  formations: []
}