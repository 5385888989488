import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import { ProductionTableItem } from "./Components/ProductionTableItem";
import { Requester } from "../../../Requester/Requester";
import { TLieu } from "../../../Types/Lieu.type";
import { TProduction } from "../../../Types/Production.type";

export function AdminProductions() {
  const [productions, setProductions] = useState<TProduction[]>([]);
  const [productionsFilter, setProductionsFilter] = useState<TProduction[]>([]);
  const [lieux, setLieux] = useState<TLieu[]>([]);
  const [searchName, setSearchName] = useState<string>("");
  const [searchLieu, setSearchLieu] = useState<number>(0);
  const [searchProduct, setSearchProduct] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const fillProductions = async () => {
      const response = await Requester.productions.getAll();
      if (response.data) {
        setProductions(response.data);
      }
    };
    fillProductions();
  }, [location]);

  useEffect(() => {

    const fillLieux = async () => {
      const response = await Requester.lieux.getAll();
      if (response.data) {
        setLieux(response.data);
      }
    };
    fillLieux();

  },[])

  useEffect(() => {
    setProductionsFilter(
      productions
        .filter((item) =>
          {
            let result = true ;

            if (searchName) {
              result &&= item.name.toLowerCase().search(searchName.toLowerCase()) > -1
            }
            if (searchLieu) {
              result &&= item.locality.id === searchLieu
            }

            if (searchProduct) {
              result &&= `${item.product.varietyName} ${item.product.name}`.toLowerCase().search(searchProduct.toLowerCase()) > -1
            }

            return result ;
          }
        )
        .sort((a, b) => {
          let fullA = a.start;
          let fullB = b.start;
          if (fullA < fullB) {
            return -1;
          } else if (fullA > fullB) {
            return 1;
          }
          return 0;
        })
        .reverse()
    );
  }, [productions, searchName, searchLieu, searchProduct]);


  return (
    <nav>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h3>Gestion des Productions</h3>
        <Link to={`/admin/productions/new`} className="icon-rondelle">
          <img
            src="/bordures/icon-rondelle-add.svg"
            alt="Ajout d'une production"
          />
        </Link>
      </div>
      <div className="bambou-border-large main-box">
        <Table striped hover>
          <thead>
            <tr>
              <th className={"col-small"}><div>Photos</div></th>
              <th>
                <div>
                  <label htmlFor="search-name">Titre :</label>{" "}
                  <input
                    id="search-name"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  ></input>
                </div>
              </th>
              <th>
                <div>
                <label htmlFor="search-locality">Lieu :</label>{" "}
                <select
                  id="search-locality"
                  value={searchLieu}
                  onChange={(e) => setSearchLieu(Number(e.target.value))}
                >
                  <option key={0} value={0}>
                    {"Tous"}
                  </option>
                  {lieux.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                </div>
              </th>
              <th>
                <div>
                <label htmlFor="search-produit">Produit :</label>{" "}
                <input
                  id="search-produit"
                  value={searchProduct}
                  onChange={(e) => setSearchProduct(e.target.value)}
                ></input>
                </div>
              </th>
              <th className={"col-small"}><div>Debut</div></th>
              <th className={"col-small"}><div>Fin</div></th>
              <th className={"col-small"}><div>Actions</div></th>
            </tr>
          </thead>
          <tbody>
            {productionsFilter.map((item, i) => (
              <ProductionTableItem key={i} production={item} />
            ))}
          </tbody>
        </Table>
      </div>
    </nav>
  );
}
