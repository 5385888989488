import { TPhoto } from "../../../Types/Photo.type";


export function AdminPhotosUpdate(props: {
  photos: TPhoto[];
  setPhotos: (value: TPhoto[]) => void;
  files: FileList | null;
  setFiles: (value: FileList | null) => void;
}) {
  const { photos, setPhotos, files, setFiles } = props;

  const filesChange = (value: FileList | null) => {
    const newPhotos = [...photos].filter((item) => item.id);
    if (value) {
      for (const file of value) {
        newPhotos.push({
          dir: "",
          path: "",
          file: file.name,
          alt: file.name.split(".")[0],
        });
      }
    }

    setFiles(value);
    setPhotos(newPhotos);
  };

  const filedPhotos = photos.filter((item) => !item.id);
  const existingPhotos = photos.filter((item) => item.id);

  const changeAlt = (name: string, value: string) => {
    setPhotos(
      [...photos].map((photo) => {
        if (photo.file === name) {
          photo.alt = value;
        }
        return photo;
      })
    );
  };

  const deletePhoto = (id: number) => {
    setPhotos([...photos].filter((photo) => photo.id !== id));
  };

  return (
    <>
    <hr />
    <h5>Photos</h5>
      <div className="admin-label-input">
        <label htmlFor="actualite-update-photos">Choix des Photos : </label>

        <input
          type="file"
          id="update-photos"
          name="update-photos"
          onChange={(e) => {
            filesChange(e.target.files);
          }}
          multiple
          accept="image/png, image/jpeg"
        />
      </div>
      <div>
        {filedPhotos.map((item, i) => (
          <div key={i} className="admin-label-input-newphoto">
            <label htmlFor={`new-photo-${i}`}>
              Texte Alternatif pour la nouvelle photo {item.file}
            </label>
            <input
              type="text"
              id={`new-photo-${i}`}
              defaultValue={item.alt}
              onChange={(e) => changeAlt(item.file, e.target.value)}
            />
          </div>
        ))}
      </div>
      <div>
        {existingPhotos.map((item, i) => (
          <div key={i} className="d-flex">
            <img
              src={`${process.env.REACT_APP_API_URL}/photos/${item.path}`}
              className="medium-image p-1 m-1"
            />
            <div className="flex-fill">
              <div className="admin-label-input-photo">
                <label
                  htmlFor={`update-photo-${item.id}`}
                  className="d-block p-1 m-1"
                >
                  Texte Alternatif
                </label>
                <input
                  className="d-block p-1"
                  type="text"
                  id={`update-photo-${item.id}`}
                  defaultValue={item.alt || ""}
                  onChange={(e) => changeAlt(item.file, e.target.value)}
                />
              </div>
              <button
                className="border-0 bg-transparent icon-rondelle"
                onClick={(e) => {
                  e.preventDefault();
                  deletePhoto(Number(item.id));
                }}
              >
                <img
                  className="icon-medium"
                  src="/bordures/icon-rondelle-delete.svg"
                  alt="Supprimer la photo"
                />
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
