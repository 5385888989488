import { useState, useEffect } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { Requester } from "../../../Requester/Requester";
import { TContact } from "../../../Types/Contact.type";

export function AdminContactView() {
  const id = useLoaderData() as number;

  const [contact, setContact] = useState<TContact>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillContact = async () => {
      const response = await Requester.contacts.getOne(id);
      if (response.data) {
        setContact(response.data);
      } else {
        setContact(undefined);
        navigate(`/admin/contacts`);
      }
    };

    fillContact();
  }, [id, navigate]);

  return (
    <>
      {contact !== undefined && (
        <>
          <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
            <h4>{contact.firstName} {contact.lastName}</h4>
            <div>
              <Link to={`/admin/contacts/update/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-update.svg"
                  alt="Modifier un contact"
                />
              </Link>

              <Link to={`/admin/contacts/delete/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-delete.svg"
                  alt="Supprimer un contact"
                />
              </Link>
            </div>
          </div>

          <div className="bambou-border-large main-box">
            <p>Mail : {contact.mail}</p>
            <p>Téléphone : {contact.phone}</p>
          </div>
        </>
      )}
    </>
  );
}
