import { useState, useEffect } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { AdminPhotosView } from "../Photos/AdminPhotosView";
import { ContactView } from "./Components/ContactView";
import { StepView } from "./Components/StepView";
import { OrderedItemView } from "./Components/OrderedItemView";
import { Requester } from "../../../Requester/Requester";
import { TContact } from "../../../Types/Contact.type";
import { TModule } from "../../../Types/Module.type";

function sortContacts(a: TContact, b: TContact) {
  const aFull = `${a.firstName} ${a.lastName}`;
  const bFull = `${b.firstName} ${b.lastName}`;
  if (aFull < bFull) return -1;
  if (aFull > bFull) return 1;
  return 0;
}
export function AdminModuleView() {
  const id = useLoaderData() as number;

  const [module, setModule] = useState<TModule>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillModule = async () => {
      const response = await Requester.modules.getOne(id);
      if (response.data) {
        response.data.contacts.sort(sortContacts);
        setModule(response.data);
      } else {
        setModule(undefined);
        navigate(`/admin/modules`);
      }
    };

    fillModule();
  }, [id, navigate]);

  return (
    <>
      {module !== undefined && (
        <>
          <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
            <h4>{module.title}</h4>
            <div>
              <Link to={`/admin/modules/update/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-update.svg"
                  alt="Modifier un module"
                />
              </Link>

              <Link to={`/admin/modules/delete/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-delete.svg"
                  alt="Supprimer un module"
                />
              </Link>
            </div>
          </div>

          <div className="bambou-border-large main-box">
            <h5>Généralités</h5>
            {module.content.split("\n").map(item=> <p>{item}</p>)}
            <p>Taille de groupe : {module.group_size}</p>
            <p>Prix : {module.price}€</p>
            <StepView values={module.formation_steps}/>
            <OrderedItemView title="Prérequis" values={module.formation_prerequests}/>
            <OrderedItemView title="Objectifs" values={module.formation_objectifs}/>
            <OrderedItemView title="Outils pédagogiques" values={module.formation_methods}/>
            <OrderedItemView title="Outils de diagnostic et d’évaluation" values={module.formation_evaluations}/>
            <ContactView values={module.contacts}/>
            <AdminPhotosView photos={module.photos} />
          </div>
        </>
      )}
    </>
  );
}
