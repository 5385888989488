import { TModule } from "../../../Types/Module.type";

export function ModuleViewMethodes(props: { module: TModule }) {
  const { module } = props;
  return (
    <div className="p-3 high-light-box-c rounded-5">
      <h3 className="text-center">Outils pédagogiques</h3>
      <p className="m-2 text-center">
        {module.formation_methods[0].name[0].toUpperCase()}
        {module.formation_methods
          .map((item) => item.name)
          .join(", ")
          .substring(1)}
      </p>
    </div>
  );
}
