import { TProduction } from "../Types/Production.type";
import { DEFAULT_LIEU } from "./lieu.default";
import { DEFAULT_PRODUIT } from "./produit.default";

export const DEFAULT_PRODUCTION : TProduction = {
  id: -1,
  name: "Nom",
  description: "Description",
  start: new Date().toISOString(),
  startquantity: 0,
  end: null,
  endquantity: null,
  locality: DEFAULT_LIEU,
  product: DEFAULT_PRODUIT,
  photos: []
}