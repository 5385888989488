import { TModule } from "../../../Types/Module.type";
import { PhotosView } from "../../App/components/Photos/PhotosView";

interface TModuleViewInfo {
  module: TModule;
  transitionTime: number;
  standTime: number;
}
export function ModuleViewInfo({
  module,
  transitionTime,
  standTime,
}: TModuleViewInfo) {
  return (
    <div className="p-3 high-light-box-a rounded-5">
      <h2 className="text-center">{module.title}</h2>
      <div className="d-md-flex ps-3">
        <PhotosView
          photos={module.photos}
          transitionTime={transitionTime}
          standTime={standTime}
          size={"20em"}
          maxSize={"75vw"}
          radius={"2em"}
        />
        <div className="p-3">
          {module.content.split("\n").map((item, i) => (
            <p key={i}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
}
