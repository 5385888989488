import { RequestMethods } from "../Types/RequestMethod.enum";
import { RequesterBase } from "./RequesterBase";

export class RequesterNames extends RequesterBase {
  route : string
  constructor(route : string){
    super();
    this.route = route
  }
  async getAll(): Promise<string[]> {
    const response = await RequesterNames.base<string[], string>(
      this.route,
      RequestMethods.GET,
      []
    );
    return response.data;
  }
}
