import { Link } from "react-router-dom";
import { TProduit } from "../../../../Types/Produit.type";

export function ProduitTableItem(props: { product: TProduit }) {
  const { product } = props;


  return (
    <tr className={"compact"}>
      <td>
        <Link to={`/admin/produits/view/${product.id}`}>
        {product.photos.length > 0 && (
          <img
            className={"compact"}
            crossOrigin="anonymous"
            src={`${process.env.REACT_APP_API_URL}/photos/${product.photos[0].path}`}
            alt={product.photos[0].alt}
          />
        )}
        </Link>
      </td>
      <td>
        <Link to={`/admin/produits/view/${product.id}`}>
        {product.varietyName} {product.name}
        </Link>
      </td>
      <td>
        <Link to={`/admin/produits/view/${product.id}`}>
          {product.quantity}kg
        </Link>
      </td>
      <td>
        <Link to={`/admin/produits/view/${product.id}`}>
          {product.price}€/kg
        </Link>
      </td>
      <td>
        <div>
        <Link to={`/admin/produits/update/${product.id}`}>
          <img
            className="icon-rondelle"
            src="/bordures/icon-rondelle-update.svg"
            alt="Modifier un produit"
          />
        </Link>
        <Link to={`/admin/produits/delete/${product.id}`}>
          <img
            className="icon-rondelle"
            src="/bordures/icon-rondelle-delete.svg"
            alt="Supprimer un produit"
          />
        </Link>
        </div>
      </td>
    </tr>
  );
}
