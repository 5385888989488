import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export function LinkCustom( props: { to: string, title: string } )
{
    const{ to, title } = props;

    return(
        <LinkContainer to={to}>
            <Nav.Link>{title}</Nav.Link>
        </LinkContainer>
    )
}