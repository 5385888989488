import { useState, useEffect, useContext } from "react";
import { Link, Form, useLoaderData, useNavigate } from "react-router-dom";
import { DEFAULT_MODULE } from "../../../Constants/module.default";
import { Requester } from "../../../Requester/Requester";
import { TContact } from "../../../Types/Contact.type";
import { GroupSize } from "../../../Types/GroupSize";
import { TModule } from "../../../Types/Module.type";
import { TModuleItemOrders } from "../../../Types/ModuleItemOrders.type";
import { TModuleSteps } from "../../../Types/ModuleStep.type";
import { TPhoto } from "../../../Types/Photo.type";
import { AdminPhotosUpdate } from "../Photos/AdminPhotosUpdate";
import { TokenContext } from "../TokenContext";
import { ContactUpdate } from "./Components/ContactUpdate";
import { OrderedItemUpdate } from "./Components/OrderedItemUpdate";
import { StepUpdate } from "./Components/StepUpdate";

export function AdminModuleUpdate() {
  const id = useLoaderData() as number;

  const { token } = useContext(TokenContext);
  const [module, setModule] = useState<TModule>(DEFAULT_MODULE);
  const [filesPhotos, setFilesPhotos] = useState<FileList | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fillModule = async () => {
      const response = await Requester.modules.getOne(id);
      if (response.data) {
        setModule(response.data);
      } else {
        setModule(DEFAULT_MODULE);
        navigate(`/admin/modules`);
      }
    };

    if (id === -1) {
      setModule(DEFAULT_MODULE);
    } else {
      fillModule();
    }
  }, [id, navigate]);

  const handleModif = (
    key: "title" | "content" | "price" | "duration",
    value: string
  ) => {
    const newValue = { ...module };
    if (key === "price" || key === "duration") {
      newValue[key] = Number(value);
    } else {
      newValue[key] = value;
    }
    setModule(newValue);
  };

  const handleContacts = (value: TContact[]) => {
    const newValue = { ...module };
    newValue.contacts = value;
    setModule(newValue);
  };
  const handleSteps = (value: TModuleSteps[]) => {
    const newValue = { ...module };
    newValue.formation_steps = value;
    setModule(newValue);
  };

  const handleSize = (value : GroupSize) => {
    const newValue = { ...module };
    newValue.group_size = value;
    setModule(newValue);
  }

  const handleOrderItem = (
    key:
      | "formation_prerequests"
      | "formation_evaluations"
      | "formation_objectifs"
      | "formation_methods",
    value: TModuleItemOrders[]
  ) => {
    const newValue = { ...module };
    newValue[key] = value;
    setModule(newValue);
  };

  const handleRequest = async () => {
    if (id !== -1) {
      const response = await Requester.modules.update(
        id,
        module,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/modules/view/${id}`);
    } else {
      const response = await Requester.modules.create(
        module,
        token,
        filesPhotos ? filesPhotos : undefined
      );
      await Promise.all([response]);
      navigate(`/admin/modules/view/${response.data.id}`);
    }
  };

  const handlePhotos = (value: TPhoto[]) => {
    const newModule = { ...module };
    newModule.photos = value;
    setModule(newModule);
  };

  return (
    <>
      <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
        <h4>{id === -1 ? "Nouveau Module" : "Modification d'un Module"}</h4>
        <div>
          <Link
            to={`/admin/modules/view/${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleRequest();
            }}
          >
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-save.svg"
              alt="Enregistrer le module"
            />
          </Link>
          <Link to={`/admin/modules/view/${id}`}>
            <img
              className="icon-rondelle"
              src="/bordures/icon-rondelle-cancel.svg"
              alt="Annuler"
            />
          </Link>
        </div>
      </div>
      <Form className="bambou-border-large main-box">
        <h5>Généralités</h5>
        <div className="admin-label-input">
          <label htmlFor="module-update-title">Titre</label>
          <input
            type="text"
            id="module-update-title"
            value={module.title}
            onChange={(e) => handleModif("title", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="module-update-content">Contenu</label>
          <textarea
            id="module-update-content"
            value={module.content}
            onChange={(e) => handleModif("content", e.target.value)}
          />
        </div>
        <div className="admin-label-input">
          <label htmlFor="module-update-size">Taille de groupe</label>
          <select
            id="module-update-size"
            value={module.group_size}
            onChange={(e) => handleSize(e.target.value as GroupSize)}
          >
            <option value={GroupSize.S}>{GroupSize.S}</option>
            <option value={GroupSize.M}>{GroupSize.M}</option>
            <option value={GroupSize.L}>{GroupSize.L}</option>
          </select>
        </div>
        <div className="admin-label-input">
          <label htmlFor="module-update-price">Prix</label>
          <input
            type="number"
            id="module-update-price"
            value={module.price}
            onChange={(e) => handleModif("price", e.target.value)}
          />
        </div>
        <StepUpdate values={module.formation_steps} setValues={handleSteps} />
        <OrderedItemUpdate
          values={module.formation_prerequests}
          setValues={(val) => handleOrderItem("formation_prerequests", val)}
          table={"prerequests"}
          title={"Prérequis"}
          itemName={"prérequis"}
        />
        <OrderedItemUpdate
          values={module.formation_objectifs}
          setValues={(val) => handleOrderItem("formation_objectifs", val)}
          table={"objectifs"}
          title={"Objectifs"}
          itemName={"objectif"}
        />
        <OrderedItemUpdate
          values={module.formation_methods}
          setValues={(val) => handleOrderItem("formation_methods", val)}
          table={"methods"}
          title={"Outils pédagogiques"}
          itemName={"outil"}
        />
        <OrderedItemUpdate
          values={module.formation_evaluations}
          setValues={(val) => handleOrderItem("formation_evaluations", val)}
          table={"evaluations"}
          title={"Outils de diagnostic et d’évaluation"}
          itemName={"outil"}
        />
        <ContactUpdate values={module.contacts} setValues={handleContacts} />
        <AdminPhotosUpdate
          photos={module.photos}
          setPhotos={handlePhotos}
          files={filesPhotos}
          setFiles={setFilesPhotos}
        />
      </Form>
    </>
  );
}
