import { Link } from "react-router-dom";
import { TActuality } from "../../../Types/Actuality.type";
import { PhotosView } from "../../App/components/Photos/PhotosView";

interface IActualiteListCardProps {
  actualite: TActuality;
  transitionTime: number;
  standTime: number;
  style?: React.CSSProperties;
}
export default function ActualiteListCard({
  actualite,
  transitionTime,
  standTime,
  style,
}: IActualiteListCardProps) {
  return (
    <Link
      to={`/actualite/${actualite.id}`}
      className="d-block text-center item-card actualite m-2"
      style={style}
    >
      <div className="d-flex d-md-block">
        <div className="d-flex flex-column justify-content-between">
          <h5>{actualite.title}</h5>
          <p>{new Date(actualite.date).toLocaleDateString()}</p>
        </div>
        <PhotosView
          photos={actualite.photos}
          transitionTime={transitionTime}
          standTime={standTime}
          size={"100%"}
          maxSize={"100%"}
          radius={"0"}
        />
      </div>
    </Link>
  );
}
