import { TModule } from "../../../Types/Module.type";

export function ModuleViewEvaluations(props: { module: TModule }) {
  const { module } = props;
  return (
    <div className="p-3 high-light-box-d rounded-5">
      <h3 className="text-center">
        Outils de diagnostic et d’évaluation
      </h3>
      {module.formation_evaluations.map((item, i) => (
        <p className="m-2 text-center" key={i}>
          {item.name[0].toUpperCase()}
          {item.name.substring(1)}
        </p>
      ))}
    </div>
  );
}
