import { useState, useEffect } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { Requester } from "../../../Requester/Requester";
import { TProgramme } from "../../../Types/Programme.type";
import { minToHourString } from "../../../Utilities/MinToHourString";

export function AdminProgrammeView() {
  const id = useLoaderData() as number;

  const [programme, setProgramme] = useState<TProgramme>();

  const navigate = useNavigate();
  useEffect(() => {
    const fillProgramme = async () => {
      const response = await Requester.programmes.getOne(id);
      if (response.data) {
        setProgramme(response.data);
      } else {
        setProgramme(undefined);
        navigate(`/admin/programmes`);
      }
    };

    fillProgramme();
  }, [id, navigate]);

  return (
    <>
      {programme !== undefined && (
        <>
          <div className="bambou-border-large d-flex justify-content-between align-items-center admin-title-bar">
            <h4>{programme.title}</h4>
            <div>
              <Link to={`/admin/programmes/update/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-update.svg"
                  alt="Modifier un programme"
                />
              </Link>

              <Link to={`/admin/programmes/delete/${id}`}>
                <img
                  className="icon-rondelle"
                  src="/bordures/icon-rondelle-delete.svg"
                  alt="Supprimer un programme"
                />
              </Link>
            </div>
          </div>

          <div className="bambou-border-large main-box">
            <h5>Généralités</h5>
            <p>{programme.content}</p>
            <hr/>
            <h5>Modules</h5>
            <table>
              <tbody>
                {programme?.formations.map((item, i) => (
                  <tr key={i}>
                    <td></td>
                    <td className="px-3">{item.title}</td>
                    <td className="px-3">{minToHourString(item.duration)}</td>
                    <td className="px-3 text-center">{item.price}€</td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td>
                    <hr />
                  </td>
                  <td>
                    <hr />
                  </td>
                  <td>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td className="px-3">
                    <strong>Totaux</strong>
                  </td>
                  <td className="px-3">
                    {" "}
                    {programme?.formations.length} Module
                    {programme && programme.formations.length > 1 ? "s" : ""}
                  </td>
                  <td className="px-3">
                    {module !== undefined
                      ? `${minToHourString(
                          programme?.formations.reduce(
                            (acc, item) => acc + item.duration,
                            0
                          )
                        )}`
                      : ""}
                  </td>
                  <td className="px-3">
                    <del>
                      {module !== undefined
                        ? `${programme?.formations.reduce(
                            (acc, item) => acc + item.price,
                            0
                          )}€`
                        : ""}
                    </del>
                  </td>
                </tr>
                <tr>
                  <td className="px-3">
                    <label htmlFor="programme-update-price">Prix</label>
                  </td>
                  <td>
                    <hr />
                  </td>
                  <td>
                    <hr />
                  </td>
                  <td className="text-center">{programme.price}€</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
